import React, { useState, useEffect } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import useStore from "@/zustand/store"; // Zustand store for state management
import { useToast } from "@/components/ui/use-toast"; // Custom toast notifications
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import CountrySelect from "../CountrySelect/CountrySelect";
import FormCheckbox from "../FormCheckBox/FormCheckbox";
import FormInput from "../FormInput/FormInput";
import LogoInput from "../LogoInput/LogoInput";
import GSTDocumentInput from "../GstDocInput/GSTDocumentInput";
import TermsAndConditionsDialog from "../Term&ConditionDialog/TermsAndConditionsDialog";
import usePageUnloadWarning from "@/hooks/usePageUnloadWarning";
// Define the form data interface
interface CreateProfileFormData {
  country: string;
  buisnessEmail: string;
  buisnessWebsite: string;
  businessName: string;
  taxNumber: string;
  termsAndConditions: boolean;
  logo: FileList;
  gstDocument: FileList;
}

// Constants
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2 MB in bytes

// Custom validation function for file size
const validateFileSize = (fileList: FileList): boolean | string => {
  if (fileList.length === 0) return true; // No file, validation not required
  const file = fileList[0];
  return file.size <= MAX_FILE_SIZE || "File size must not exceed 2 MB";
};

// Main component
const CreateProfileForm: React.FC = () => {
  const { createProfile } = useStore(); // Function to create profile from Zustand store
  const { toast } = useToast(); // Toast notification handler
  const [loading, setLoading] = useState<boolean>(false); // Loading state
  const [currentStep, setCurrentStep] = useState<number>(1); // Step state
  const [showTerms, setShowTerms] = useState<boolean>(false); // State for showing Terms dialog
  const navigate = useNavigate();

  // Use React Hook Form for form management
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isDirty, errors },
    trigger,
    watch,
  } = useForm<CreateProfileFormData>();

  // Watch specific fields for changes
  const logo = watch("logo");
  const gstDocument = watch("gstDocument");
  const businessName = watch("businessName");
  const taxNumber = watch("taxNumber");
  const country = watch("country");
  const buisnessEmail = watch("buisnessEmail");
  const buisnessWebsite = watch("buisnessWebsite");
  const termsAndConditions = watch("termsAndConditions");

  // Clear errors on field change
  useEffect(() => {
    if (logo && logo.length > 0) {
      trigger("logo"); // Trigger validation for logo
    }
  }, [logo, trigger]);

  useEffect(() => {
    if (gstDocument && gstDocument.length > 0) {
      trigger("gstDocument"); // Trigger validation for GST Document
    }
  }, [gstDocument, trigger]);

  // A single useEffect to clear all relevant errors
  useEffect(() => {
    const clearErrors = async () => {
      if (businessName) {
        await trigger("businessName");
      }
      if (taxNumber) {
        await trigger("taxNumber");
      }
      if (country) {
        await trigger("country");
      }
      if (buisnessEmail) {
        await trigger("buisnessEmail");
      }
      if (buisnessWebsite) {
        await trigger("buisnessWebsite");
      }
      if (termsAndConditions) {
        await trigger("termsAndConditions");
      }
    };

    clearErrors();
  }, [
    businessName,
    taxNumber,
    country,
    buisnessEmail,
    buisnessWebsite,
    termsAndConditions,
    trigger,
  ]);
  usePageUnloadWarning(isDirty);


  // Handle form submission
  const onSubmit: SubmitHandler<CreateProfileFormData> = async (data) => {
    setLoading(true); // Start loading state

    // Prepare FormData for file uploads and other fields
    const formData = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === "logo" || key === "gstDocument") {
        if (value.length > 0) {
          formData.append(key, value[0]);
        }
      } else {
        formData.append(key, value);
      }
    });


    // Log form data for debugging
    console.log("Form Data: ", data);

    try {
      // Call the action to create the profile
      const success = await createProfile(formData);

      // Display toast notification based on success or failure
      if (success) {
        toast({
          title: "Success",
          description: "Profile created successfully!",
          variant: "default",
          className: "bg-green-500 text-white",
        });
        console.log("Profile created successfully.");
        navigate("/register/complete");
      } else {
        throw new Error("Profile creation failed."); // Trigger error handling
      }
    } catch (error) {
      toast({
        title: "Error",
        description:
          error instanceof Error ? error.message : "Profile creation failed. Please try again.",
        variant: "destructive",
      });
      console.error("Profile creation failed.", error);
    } finally {
      setLoading(false); // End loading state
    }
  };

  // Move to the next step after validation
  const handleNextStep = async () => {
    const isValid = await trigger(); // Validate current step
    if (isValid) {
      setCurrentStep((prev) => prev + 1); // Move to next step
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="create-profile-form font-Inter" aria-label="Create Profile Form">
      {currentStep === 1 && (
        <>
          <LogoInput
            id="logo"
            label="Business/Brand logo" // Label added here
            register={register("logo", {
              required: "Logo file is required",
              validate: (value) => validateFileSize(value) || true,
            })}
            error={errors.logo}
          />

          <FormInput
            id="businessName"
            label="Business Name"
            placeholder="business name here"
            type="text"
            register={register("businessName", {
              required: "Business name is required",
              validate: (value) => value.trim() !== "" || "Business name cannot be empty or just spaces",
            })}
            error={errors.businessName}
          />
          <CountrySelect
            id="country"
            register={register} // Pass the register function directly
            setValue={setValue} // Pass the setValue function here
            error={errors.country}
          />
          <FormInput
            id="buisnessEmail"
            label="Business Email"
            placeholder="xyz@companyname"
            type="email"
            register={register("buisnessEmail", {
              required: "Business email is required",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address (e.g., name@example.com)",
              },
            })}
            error={errors.buisnessEmail}
          />
          <FormInput
            id="buisnessWebsite"
            label="Business Website"
            placeholder="https://yourcompanywebsite"
            type="url"
            register={register("buisnessWebsite", {
              required: "Business website is required",
              pattern: {
                value: /^(https?:\/\/.+)$/,
                message: "Invalid URL (e.g., https://example.com)",
              },
            })}
            error={errors.buisnessWebsite}
          />
          <Button
            type="button"
            onClick={handleNextStep}
            className="border-2 border-Primary w-full md:w-80 lg:w-96 p-3 md:px-6 rounded-full text-black dark:text-white font-Inter font-semibold 
  bg-Primary dark:bg-black hover:scale-105 hover:bg-Primary transition-transform transform ease-in-out duration-300"
          >
            Next
          </Button>

        </>
      )}

      {currentStep === 2 && (
        <>
          <FormInput
            id="taxNumber"
            label="Tax ID number"
            placeholder="GST/VAT number"
            type="text"
            register={register("taxNumber", {
              required: "Tax number is required",
              validate: (value) => value.trim() !== "" || "Tax number cannot be empty or just spaces",
            })}
            error={errors.taxNumber}
          />
          <GSTDocumentInput
            id="gstDocument"
            register={register("gstDocument", {
              required: "GST document is required",
              validate: (value) => validateFileSize(value) || true,
            })}
            error={errors.gstDocument}
          />
          {/* <FormCheckbox
            id="termsAndConditions"
            label={
              <span className="text-sm">
                By checking this box, I confirm that I have read, understood, and agree to be bound by the {""}
                <span
                  onClick={() => setShowTerms(true)} // Open the dialog
                  className="text-blue-500 font-bold  cursor-pointer"
                >
                  Terms and Conditions
                </span >
                {""} and {""}
                <span
                  onClick={() => setShowTerms(true)} // Open the dialog
                  className="text-blue-500 font-bold cursor-pointer">
                  Privacy Policy.
                </span>
              </span>
            }
            register={register("termsAndConditions", {
              required: "You must accept terms and conditions",
            })}
            error={errors.termsAndConditions}
          /> */}
          <div className="flex flex-col lg:w-96 mb-4 mt-2">
            <div className="flex gap-2">
              <input
                id="termsAndConditions"
                type="checkbox"
                {...register("termsAndConditions", {
                  required: "You must accept terms and conditions",
                })}
                aria-invalid={!!errors.termsAndConditions}
              />
              <label htmlFor="termsAndConditions">
                <span className="text-sm">
                  By checking this box, I confirm that I have read, understood, and agree to be bound by the {""}
                  <span
                    onClick={() => setShowTerms(true)} // Open the dialog
                    className="text-blue-500 font-bold  cursor-pointer"
                  >
                    Terms and Conditions
                  </span >
                  {""} and {""}
                  <span
                    onClick={() => setShowTerms(true)} // Open the dialog
                    className="text-blue-500 font-bold cursor-pointer">
                    Privacy Policy.
                  </span>
                </span>
              </label>
            </div>
            {errors.termsAndConditions && (
              <span role="alert" style={{ color: 'red' }}>
                {errors.termsAndConditions.message}
              </span>
            )}

          </div>

          <Button
            type="submit"
            disabled={loading}
            className={`border-2 border-Primary w-full lg:w-96  p-3 px-6 rounded-full text-black dark:text-white font-Inter font-semibold 
    bg-Primary dark:bg-black hover:scale-105 transition-transform transform ease-in-out duration-300
    ${loading ? "opacity-50 cursor-not-allowed" : "hover:bg-Primary-dark"}`}
          >
            {loading ? "Creating Profile..." : "Create Profile"}
          </Button>

        </>
      )}

      {/* Terms and Conditions Dialog */}
      <TermsAndConditionsDialog open={showTerms} onOpenChange={setShowTerms} />
    </form>
  );
};

export default CreateProfileForm;

import { useQuery } from "@tanstack/react-query";
import api from "@/utils/api"; // Assuming you have an API utility set up

// Custom hook for fetching the budget
export const useFetchBudget = () => {
  return useQuery<number, Error>({
    queryKey: ["totalBudget"], // Cache key
    queryFn: async () => {
      const response = await api.get("/wallet/getBudget");
      return Number(response.data?.totalBudget) || 0; // Return total budget for allowed roles
    },
    staleTime: 5 * 60 * 1000, // 5 minutes cache time
    retry: 2, // Retry failed requests up to 2 times
    refetchOnWindowFocus: false, // Disable refetch on window focus
  });
};

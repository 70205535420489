import React, { useEffect } from "react";
import { useFormContext, FieldValues, FieldError } from "react-hook-form";
import useStore from "@/zustand/store"; // Zustand store import
import { Checkbox } from "@/components/ui/checkbox"; // Assuming you have a checkbox component in ShadCN UI

interface TargetingDevicesFieldProps {
    value?: string[] | null;
}

const TargetingDevicesField: React.FC<TargetingDevicesFieldProps> = () => {
    const { register, setValue, watch, formState: { errors } } = useFormContext<FieldValues>();

    // Zustand store action to update the targetingDevices in the global state
    const setTargetingDevices = useStore((state) => state.setTargetingDevice);

    // Watch the selected targetingDevices value in the form state
    const selectedDevices = watch("targetingDevices") || [];
    const devicesError = errors.targetingDevices as FieldError | undefined;

    // Sync selected devices to Zustand when they change
    useEffect(() => {
        setTargetingDevices(selectedDevices);
    }, [selectedDevices, setTargetingDevices]);

    // Handle checkbox change (no manual onChange)
    const handleDeviceChange = (value: string, checked: boolean) => {
        const updatedDevices = checked
            ? [...selectedDevices, value] // Add if checked
            : selectedDevices.filter((device: string) => device !== value); // Remove if unchecked

        // Update form value
        setValue("targetingDevices", updatedDevices, { shouldValidate: true });
    };

    return (
        <div className="flex flex-col mt-4 font-Inter">
            <label className="font-semibold text-sm lg:text-base">
                Targeting Devices <span className="text-sm text-gray-400">(Optional)</span>
            </label>
            <div className="flex flex-col gap-2 mt-2 font-semibold text-sm">
                {["Android", "iOS", "Other mobile", "Desktop"].map((device) => (
                    <div key={device} className="flex items-center">
                        <Checkbox
                            id={`device-${device}`}
                            value={device}
                            checked={selectedDevices.includes(device)} // This ensures that the checkbox reflects the selected state
                            onCheckedChange={(checked: boolean) => handleDeviceChange(device, checked)} // Handle change here
                            {...register("targetingDevices", {
                                // required: "At least one device must be selected" // Add required validation
                            })}
                            className="form-checkbox text-Primary border-gray-200 bg-white"
                        />
                        <label htmlFor={`device-${device}`} className="ml-2">
                            {device}
                        </label>
                    </div>
                ))}
            </div>
            {devicesError && devicesError.message && (
                <p className="text-red-500 text-sm pb-2 px-2 mt-2">{devicesError.message}</p>
            )}
        </div>
    );
};

export default TargetingDevicesField;

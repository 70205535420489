import { Textarea } from '@/components/ui/textarea';
import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface TextAreaProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  maxLength?: number;
  register?: UseFormRegisterReturn; // Add register prop to handle form registration
}

const TextArea: React.FC<TextAreaProps> = React.memo(({ label, value, onChange, placeholder, maxLength, register }) => (
  <div>
    <label className="block text-sm font-medium text-gray-700 mb-2 font-Inter dark:text-white">
      {label}
      {<span className="text-red-500"> *</span>}
    </label>
    <Textarea
      className="w-full border-2 rounded-md"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      maxLength={maxLength}
      {...register} // Spread the register props to connect with react-hook-form
    />
    {maxLength && (
      <p className="text-sm text-gray-500 text-right">
        {value.length}/{maxLength}
      </p>
    )}
  </div>
));

export default TextArea;

import React, { useState, useMemo } from "react";
import Cropper, { Area } from "react-easy-crop";
import { getCroppedImg } from "./cropImage";
import CropModalBackGround from "@/assets/CropModalBackGround.jpeg";
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogFooter,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { RotateCcw, RotateCw } from "lucide-react";

interface CropModalProps {
    file: File;
    onCropComplete: (croppedFile: File) => void;
    onCancel: () => void;
}

const ASPECT_RATIO = 1080/ 1920;

const CropModal: React.FC<CropModalProps> = ({ file, onCropComplete, onCancel }) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [rotation, setRotation] = useState(0);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

    const imageUrl = useMemo(() => URL.createObjectURL(file), [file]);

    const handleCropComplete = async () => {
        if (!croppedAreaPixels) return;
        try {
            const croppedBlob = await getCroppedImg(imageUrl, croppedAreaPixels, rotation);
            const croppedFile = new File([croppedBlob], file.name, { type: file.type });
            onCropComplete(croppedFile);
        } finally {
            URL.revokeObjectURL(imageUrl); // Clean up object URL
        }
    };

    const handleRotate = (direction: "left" | "right") => {
        setRotation((prevRotation) => prevRotation + (direction === "left" ? -90 : 90));
    };

    return (
        <AlertDialog open onOpenChange={(isOpen) => !isOpen && onCancel()}>
            <AlertDialogContent className=" w-72  sm:w-[512px] font-Inter rounded-lg">
                <div className="hidden sm:flex sm:flex-row items-center justify-between">
                    <Button
                        variant="ghost"
                        onClick={onCancel}
                        className="rounded-full bg-accent hover:bg-accent/50"
                    >
                        Cancel
                    </Button>
                    <AlertDialogTitle>Crop Your Image</AlertDialogTitle>
                    <Button
                        onClick={handleCropComplete}
                        variant="primary"
                        className="w-24"
                    >
                        Done
                    </Button>
                </div>

                <div
                    className="relative w-full h-80 mb-4 rounded-lg overflow-hidden border border-gray-200"
                    style={{
                        backgroundImage: `url(${CropModalBackGround})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    <Cropper
                        image={imageUrl}
                        crop={crop}
                        zoom={zoom}
                        rotation={rotation}
                        aspect={ASPECT_RATIO}
                        onCropChange={setCrop}
                        onZoomChange={setZoom}
                        onCropComplete={(_, area) => setCroppedAreaPixels(area)}
                    />
                </div>
                <div className="flex justify-center gap-2 items-center">
                    <button
                        onClick={() => handleRotate("left")}
                        className="p-2 flex flex-col items-center justify-center"
                        aria-label="Rotate Left"
                    >
                        <RotateCcw size={20} />
                        <span className="font-medium text-base ">Rotate left</span>
                    </button>
                    <button
                        onClick={() => handleRotate("right")}
                        className="p-2 flex flex-col items-center justify-center"
                        aria-label="Rotate Right"
                    >
                        <RotateCw size={20} />
                        <span className="font-medium text-base ">Rotate Right</span>
                    </button>
                </div>
                <AlertDialogFooter>
                    <div className="flex flex-row items-center justify-between sm:hidden">
                        <Button
                            variant="ghost"
                            onClick={onCancel}
                            className="rounded-full bg-accent hover:bg-accent/50"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleCropComplete}
                            variant="primary"
                            className="w-24"
                        >
                            Done
                        </Button>
                    </div>
                </AlertDialogFooter>

            </AlertDialogContent>
        </AlertDialog>
    );
};

export default CropModal;

import React from "react";
import { useFormContext, FieldValues, FieldError } from "react-hook-form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import useStore from "@/zustand/store";  // Zustand store import
import { Label } from "@/components/ui/label";

interface GenderFieldProps {
    value?: string | null;
}

const GenderField: React.FC<GenderFieldProps> = () => {
    const { register, setValue, watch, formState: { errors }, trigger } = useFormContext<FieldValues>();

    // Zustand store action to update the gender in the global state
    const setGender = useStore((state) => state.setGender);

    // Watch the selected gender value in the form state
    const selectedGender = watch("gender");
    const genderError = errors.gender as FieldError | undefined;

    // Handle gender change to update both form state and global state, and trigger validation
    const handleGenderChange = (value: string) => {
        setValue("gender", value, { shouldValidate: true });
        setGender(value);         // Update Zustand store with the selected gender
        trigger("gender");        // Trigger validation on change
    };

    return (
        <div className="flex flex-col mt-4 font-Inter">
            <Label className="font-semibold text-sm lg:text-base">
                Gender
            </Label>
            <RadioGroup
                value={selectedGender}
                onValueChange={handleGenderChange}
                className="flex flex-col lg:flex-row lg:gap-4 mt-2 text-sm font-semibold"
            >
                <div className="flex items-center">
                    <RadioGroupItem
                        value="Any"
                        id="gender-any"
                        {...register("gender", { required: "Gender is required" })}
                        className="form-radio text-Primary border-gray-200 bg-white "
                    />
                    <label htmlFor="gender-any" className="ml-2">Any</label>
                </div>
                <div className="flex items-center">
                    <RadioGroupItem
                        value="Male"
                        id="gender-male"
                        {...register("gender", { required: "Gender is required" })}
                        className="form-radio text-Primary border-gray-200 bg-white "
                    />
                    <label htmlFor="gender-male" className="ml-2">Male</label>
                </div>
                <div className="flex items-center">
                    <RadioGroupItem
                        value="Female"
                        id="gender-female"
                        {...register("gender", { required: "Gender is required" })}
                        className="form-radio text-Primary border-gray-200 bg-white "
                    />
                    <label htmlFor="gender-female" className="ml-2">Female</label>
                </div>
            </RadioGroup>
            {genderError && genderError.message && (
                <p className="text-red-500 text-sm pb-2 px-2">{genderError.message}</p>
            )}
        </div>
    );
};

export default GenderField;

import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Card, CardTitle } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { FormData } from "@/components/types/internal/types/FormDataTypes";
import PaymentMethod from "../../wallet/stripe";
import GenderField from "./demographics/genderField";
import AgeField from "./demographics/ageField";
import LocationField from "./demographics/locationField";
import InterestsField from "./targetingFeatures/interestsField";
import LanguageField from "./targetingFeatures/languageField";
import TargetingDevices from "./targetingFeatures/targetingDevices";
import DailyBudgetField from "./budget&Schedule/dailyBudgetField";
import Demopreview from "./Preview-temp/demopreview";
import JamInterestsField from "./targetingFeatures/jamInterestField";

// Define SectionType as a union of the possible section keys
type SectionType = "personal-info" | "additional-info" | "budget-info";

const FinalStep: React.FC = () => {
  const { watch, formState: { errors } } = useFormContext<FormData>();

  const [openSections, setOpenSections] = useState<Record<SectionType, boolean>>({
    "personal-info": true,
    "additional-info": true,
    "budget-info": true,
  });

  // Watch form data to trigger validation
  const age = watch("age");
  const location = watch("location");
  const gender = watch("gender");
  const jamInterests = watch("jamInterests");
  const interests = watch("interests");
  const language = watch("language");
  const dailyBudget = watch("dailyBudget");
  const startDate = watch("startDate");
  const endDate = watch("endDate");
  const targetingDevices = watch('targetingDevices');

  // Trigger accordion section open when validation errors are present
  useEffect(() => {
    setOpenSections((prev) => ({
      "personal-info": !!(errors.age || errors.location || errors.gender) || prev["personal-info"],
      "additional-info": !!(errors.jamInterests || errors.interests || errors.language || errors.targetingDevices) || prev["additional-info"],
      "budget-info": !!(errors.dailyBudget || errors.startDate || errors.endDate) || prev["budget-info"],
    }));
  }, [errors]);

  const toggleSection = (section: SectionType) => {
    setOpenSections((prev) => ({
      ...prev,
      [section]: !prev[section], // Toggle the specific section
    }));
  };

  return (
    <div className="flex flex-col min-h-screen lg:flex-row justify-center items-start gap-5 lg:gap-10 w-full bg-gray-50 p-2 lg:p-0 px-4 py-14 lg:py-1 overflow-hidden dark:bg-background">
      <Card className="w-full lg:w-2/4 p-4 lg:p-8 bg-gray-100 border border-gray-300 dark:bg-background shadow-lg rounded-lg">
        <CardTitle className="font-Inter font-semibold text-base lg:text-xl tracking-tight pl-4 pb-4">
          Customize Delivery
        </CardTitle>
        <Separator className="my-4 bg-gray-400" />

        <div className="w-full">
          {/* Accordion for Personal Information */}
          <div
            className={`mb-4 rounded-lg border ${errors.age || errors.location || errors.gender ? "border-red-500" : "border-slate-500"}`}
          >
            <div
              className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 dark:bg-background  rounded-lg"
              onClick={() => toggleSection("personal-info")}
            >
              <CardTitle className="font-Inter font-semibold text-sm lg:text-xl tracking-tight">
                Demographics
              </CardTitle>
              <span>{openSections["personal-info"] ? "-" : "+"}</span>
            </div>
            <div className={`${openSections["personal-info"] ? "block" : "hidden"} p-4 bg-gray-100 dark:bg-background  rounded-b-lg`}>
              <p className="font-Inter font-normal text-base text-gray-400">
                Narrow down your audience based on gender, age and location.
              </p>

              <GenderField value={gender} />
              <AgeField value={age} />
              <LocationField value={location} />
            </div>
          </div>

          {/* Accordion for Additional Information */}
          <div
            className={`mb-4 rounded-lg border ${errors.jamInterests || errors.interests || errors.language ? "border-red-500" : "border-slate-500"}`}
          >
            <div
              className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 dark:bg-background  rounded-lg"
              onClick={() => toggleSection("additional-info")}
            >
              <CardTitle className="font-Inter font-semibold text-sm lg:text-xl tracking-tight">
                Targeting features
              </CardTitle>
              <span>{openSections["additional-info"] ? "-" : "+"}</span>
            </div>
            <div className={`${openSections["additional-info"] ? "block" : "hidden"} p-4 bg-gray-100 dark:bg-background  rounded-b-lg`}>
              <p className="font-Inter font-normal text-base text-gray-400">
                Target your audience based on keywords they use, and their
                interests.
              </p>

              <JamInterestsField value={jamInterests} />
              <InterestsField value={interests} />
              <LanguageField value={language} />
              <TargetingDevices value={targetingDevices} />
            </div>
          </div>

          {/* Accordion for Budget Information */}
          <div
            className={`mb-4 rounded-lg border ${errors.dailyBudget || errors.startDate || errors.endDate ? "border-red-500" : "border-slate-500"}`}
          >
            <div
              className="flex items-center justify-between p-4 cursor-pointer bg-gray-100 dark:bg-background  rounded-lg"
              onClick={() => toggleSection("budget-info")}
            >
              <CardTitle className="font-Inter font-semibold text-sm lg:text-xl tracking-tight">
                Budget and schedule
              </CardTitle>
              <span>{openSections["budget-info"] ? "-" : "+"}</span>
            </div>
            <div className={`${openSections["budget-info"] ? "block" : "hidden"} p-4 bg-gray-100 dark:bg-background  rounded-b-lg`}>
              <p className="font-Inter font-normal text-base text-gray-400">
                Set a budget that fits your needs and a date range to take more
                control of your spend.
              </p>

              {/* <DailyBudgetField dailyBudget={dailyBudget} startDate={startDate} endDate={endDate} /> */}
              <DailyBudgetField />
            </div>
          </div>
        </div>
      </Card>
      <Demopreview />
    </div>
  );
};

export default FinalStep;

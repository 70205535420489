import { Label } from "@/components/ui/label";
import React from "react";
import { useFormContext, Controller, FieldValues, FieldError } from "react-hook-form";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { ChevronDown } from "lucide-react";
import useStore from "@/zustand/store";

interface AgeFieldProps {
  value: string | undefined;
}

const AgeField: React.FC<AgeFieldProps> = ({ value }) => {
  const { control, watch, setValue, formState: { errors } } = useFormContext<FieldValues>();
  const setAge = useStore((state) => state.setAge);

  const ageOption = watch("ageOption", value === "all" || value === "range" ? value : undefined);
  const minAge = watch("minAge");
  const maxAge = watch("maxAge");


  const minAgeError = errors.minAge as FieldError | undefined;
  const maxAgeError = errors.maxAge as FieldError | undefined;
  const ageOptionError = errors.ageOption as FieldError | undefined;

  const ageRange = Array.from({ length: 43 }, (_, i) => i + 13);

  // Filter maxAge options to always be greater than minAge
  const filteredMaxAgeOptions = ageRange.filter(age => age > (minAge || 12));

  // Synchronize the 'age' field with Zustand store and form state
  React.useEffect(() => {
    if (ageOption === "range" && minAge && maxAge) {
      const rangeValue = `${minAge}-${maxAge}`;
      setValue("age", rangeValue);
      setAge(rangeValue); // Update Zustand store
    } else if (ageOption === "all") {
      setValue("age", "all");
      setAge("all"); // Update Zustand store
    }
  }, [ageOption, minAge, maxAge, setValue, setAge]);

  // Ensure minAge and maxAge are valid
  React.useEffect(() => {
    if (minAge && maxAge && minAge >= maxAge) {
      const newMaxAge = ageRange.find(age => age > minAge); // Find the next valid maxAge
      setValue("maxAge", newMaxAge || ""); // Reset maxAge
    }
  }, [minAge, maxAge, setValue, ageRange]);

  return (
    <div className="flex flex-col mt-4 font-Inter">
      <Label htmlFor="ageOption" className="font-semibold text-sm lg:text-base mb-1">
        Age Options
      </Label>

      <Controller
        name="ageOption"
        control={control}
        defaultValue={value}
        rules={{
          required: "Please select an age option",
        }}
        render={({ field }) => (
          <RadioGroup
            value={field.value}
            onValueChange={(val) => {
              field.onChange(val);
              if (val === "all") {
                setValue("age", "all");
                setAge("all"); // Update Zustand store
              }
            }}
            className="flex gap-2 mb-2 flex-col"
          >
            <div className="flex items-center gap-2">
              <RadioGroupItem
                value="all"
                id="all"
                className="form-radio text-Primary border-gray-200 bg-white "
              />
              <Label htmlFor="all" className="text-sm">All</Label>
            </div>
            <div className="flex items-center gap-2">
              <RadioGroupItem
                value="range"
                id="range"
                className="form-radio text-Primary border-gray-200 bg-white "
              />
              <Label htmlFor="range" className="text-sm">Age Range</Label>
            </div>
          </RadioGroup>
        )}
      />

      {ageOptionError?.message && (
        <p className="text-red-500 text-sm pb-2 px-2">{ageOptionError.message}</p>
      )}

      {ageOption === "range" && (
        <div className="flex flex-col lg:flex-row lg:gap-4">
          <div>
            <Controller
              name="minAge"
              control={control}
              defaultValue=""
              rules={{
                required: "Minimum age is required",
                validate: value => value >= 13 || "Minimum age must be at least 13",
              }}
              render={({ field }) => (
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="w-[150px] border rounded-full text-left bg-white dark:bg-background flex items-center justify-between"
                      aria-label="Select minimum age"
                    >
                      {field.value || "min"}
                      <ChevronDown className="ml-2 h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-40 max-h-60 overflow-auto">
                    {ageRange.map((age) => (
                      <DropdownMenuItem
                        key={age}
                        onClick={() => field.onChange(age)}
                      >
                        {age}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            />
            {minAgeError?.message && (
              <p className="text-red-500 text-sm pb-2 px-2">{minAgeError.message}</p>
            )}
          </div>

          <span className="mt-2 hidden lg:block ">-</span>

          <div>
            <Controller
              name="maxAge"
              control={control}
              defaultValue=""
              rules={{
                required: "Maximum age is required",
                validate: value => value > minAge || "Maximum age must be greater than minimum age",
              }}
              render={({ field }) => (
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild>
                    <Button
                      variant="ghost"
                      className="w-[150px] p-4 border rounded-full text-left bg-white dark:bg-background flex items-center justify-between"
                      aria-label="Select maximum age"
                    >
                      {field.value || "and Up"}
                      <ChevronDown className="ml-2 h-4 w-4" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="w-40 max-h-60 overflow-auto">
                    {filteredMaxAgeOptions.map((age) => (
                      <DropdownMenuItem
                        key={age}
                        onClick={() => field.onChange(age)}
                      >
                        {age}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuContent>
                </DropdownMenu>
              )}
            />
            {maxAgeError?.message && (
              <p className="text-red-500 text-sm pb-2 px-2">{maxAgeError.message}</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AgeField;


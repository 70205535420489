import React from "react";
import { Input } from "@/components/ui/input";
import { UseFormRegisterReturn } from "react-hook-form";
import { Asterisk } from "lucide-react";

interface CTAButtonProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  maxLength?: number;
  register?: UseFormRegisterReturn;
  required?: boolean; // New prop to control label indicator
}

const CTAButton: React.FC<CTAButtonProps> = ({
  label,
  value,
  onChange,
  placeholder,
  maxLength,
  register,
  required = true, // Default to 'required' (shows asterisk by default)
}) => (
  <div className="pb-4">
    <label className="block text-sm font-medium text-gray-700 mb-2 font-Inter">
      <span className="block text-xs lg:text-sm lg:font-medium mb-2 font-Inter dark:text-white">
        {label}
        {required ? (
          <span className="text-red-500"> *</span> // Show asterisk for required fields
        ) : (
          <span className="text-gray-500"> (optional)</span> // Show 'optional' for non-required fields
        )}
      </span>
    </label>
    <Input
      type="text"
      className="w-full border-2 rounded-md p-2"
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      maxLength={maxLength}
      {...register} // Spread register props
    />
  </div>
);

export default CTAButton;

import { useEffect } from "react";

const usePageUnloadWarning = (isDirty: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        const message = "You have unsaved changes. Are you sure you want to leave?";
        
        // This is the new standard for modern browsers
        event.preventDefault();  // Required for some browsers
        return message;  // Chrome, Firefox, Safari, etc.
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isDirty]);
};

export default usePageUnloadWarning;

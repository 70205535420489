import React from "react";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Card } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import useStore from "@/zustand/store"; // Adjust the path as per your Zustand store setup
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css"; // Import Tooltip CSS
import { Info } from "lucide-react";

// Your formatDateTime function
export const formatDateTime = (date: Date): string => {
  if (!date) return '';
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short', // Abbreviated month (e.g., "Dec")
    day: '2-digit', // Two-digit day (e.g., "02")
  };
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const DemographicsPreview: React.FC = () => {
  const {
    gender,
    age,
    location,
    keywords,
    interests,
    language,
    targetingDevice,
    dailyBudget,
    startDate,
    endDate,
  } = useStore((state) => ({
    gender: state.gender,
    age: state.age,
    location: state.location,
    keywords: state.keywords,
    interests: state.interests,
    language: state.language,
    targetingDevice: state.targetingDevice,
    dailyBudget: state.dailyBudget,
    startDate: state.startDate,
    endDate: state.endDate,
  }));

  // Check if any demographic feature is provided
  const hasDemographicFeatures = gender || age !== "all" || location;
  // Check if any targeting feature is provided
  const hasTargetingFeatures = keywords || interests || language || targetingDevice;

  // Format locations with commas and spaces
  const formattedLocations = location.length > 0 ? location.join(", ") : "Not specified";

  // Format keywords, interests, and language in the same way
  const formattedKeywords = keywords.length > 0 ? keywords.join(", ") : "Not specified";
  const formattedInterests = interests.length > 0 ? interests.join(", ") : "Not specified";
  const formattedLanguage = language.length > 0 ? language.join(", ") : "Not specified";

  // Use the correct way to handle targetingDevice
  const formattedTargetingDevice = Array.isArray(targetingDevice) && targetingDevice.length > 0
    ? targetingDevice.join(", ")
    : "Not specified";  // Correct handling of targetingDevice

  // Format the budget and dates
  const formattedStartDate = startDate ? formatDateTime(new Date(startDate)) : "Not specified";
  const formattedEndDate = endDate ? formatDateTime(new Date(endDate)) : "Not specified";
  const formattedDailyBudget = dailyBudget ? `$ ${dailyBudget}` : "Not specified";

  return (
    <Card className="w-full h-full lg:w-1/4 p-5 lg:p-10 bg-gray-100 border border-gray-300 overflow-hidden pb-10 dark:bg-background">
      <h3 className="font-Inter font-semibold text-base lg:text-2xl tracking-tight pl-2 lg:pl-10">
        Audience summary
      </h3>
      <Separator className="my-2 bg-gray-400" />
      <div className="p-4">
        <div className="flex items-center justify-center gap-4">
          <h2 className="font-Inter font-semibold text-xl lg:text-3xl tracking-tight">
            38.8M
          </h2>
          <h2 className="font-Inter font-semibold text-xl lg:text-3xl tracking-tight">
            -
          </h2>
          <h2 className="font-Inter font-semibold text-xl lg:text-3xl tracking-tight">
            49.8M
          </h2>
        </div>
        <span className="text-sm font-Inter flex items-center justify-center gap-2">
          <span
          >
            Reach Potential
          </span>
          <span className="relative group"
            data-tooltip-id="reach-tooltip"> <Info size={15} /> </span>
          <Tooltip
            id="reach-tooltip"
            place="bottom"
            style={{
              width: "200px",
              borderRadius: "10px",
              backgroundColor: "#000 !important", // Full opaque black background
              color: "#fff !important",           // Text color
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2) !important",
              opacity: "1 !important",            // Force full opacity
              zIndex: "9999 !important",
            }}
          >
            The range of potential audience for your ad campaign.
          </Tooltip>
        </span>
      </div>

      <Accordion
        type="multiple"
        defaultValue={["item-1", "item-2", "item-3"]} // Open all accordions by default
        className="w-full"
      >
        {hasDemographicFeatures && (
          <AccordionItem value="item-1" className="border-none border-gray-200">
            <AccordionTrigger className="font-Inter font-semibold text-sm lg:text-xl tracking-tight">
              Demographics
            </AccordionTrigger>
            <AccordionContent>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-10">
                  <div className="flex flex-col">
                    <p className="font-Inter text-base font-normal text-gray-400">
                      Gender
                    </p>
                    <p className="font-Inter font-normal text-sm ">
                      {gender || "Any"}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-Inter text-base font-normal text-gray-400">
                      Age
                    </p>
                    <p className="font-Inter font-normal text-sm ">{age}</p>
                  </div>
                </div>
                <div className="flex flex-col">
                  <p className="font-Inter text-base font-normal text-gray-400">
                    Location
                  </p>
                  <p className="font-Inter font-normal text-sm">
                    {formattedLocations}
                  </p>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        )}

        {hasTargetingFeatures && (
          <AccordionItem value="item-3" className="border-none border-gray-200">
            <AccordionTrigger className="font-Inter font-semibold text-sm lg:text-xl tracking-tight">
              Targeting Features
            </AccordionTrigger>
            <AccordionContent>
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-10">
                  <div className="flex flex-col">
                    <p className="font-Inter text-base font-normal text-gray-400">
                      Keywords
                    </p>
                    <p className="font-Inter font-normal text-sm">
                      {formattedKeywords}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-Inter text-base font-normal text-gray-400">
                      Interests
                    </p>
                    <p className="font-Inter font-normal text-sm">
                      {formattedInterests}
                    </p>
                  </div>
                </div>
                <div className="flex flex-row gap-10">
                  <div className="flex flex-col">
                    <p className="font-Inter text-base font-normal text-gray-400">
                      Language
                    </p>
                    <p className="font-Inter font-normal text-sm">
                      {formattedLanguage}
                    </p>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-Inter text-base font-normal text-gray-400">
                      Targeting Device
                    </p>
                    <p className="font-Inter font-normal text-sm">
                      {formattedTargetingDevice}
                    </p>
                  </div>
                </div>
              </div>
            </AccordionContent>
          </AccordionItem>
        )}

        <AccordionItem value="item-2" className="border-none border-gray-200">
          <AccordionTrigger className="font-Inter font-semibold text-sm lg:text-xl tracking-tight">
            Budget and Schedule
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-10">
                <div className="flex flex-col">
                  <p className="font-Inter text-base font-normal text-gray-400">
                    Daily Budget
                  </p>
                  <p className="font-Inter font-normal text-sm ">
                    {formattedDailyBudget}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-Inter text-base font-normal text-gray-400">
                    Start Date
                  </p>
                  <p className="font-Inter font-normal text-sm">
                    {formattedStartDate}
                  </p>
                </div>
              </div>
              <div className="flex flex-col">
                <p className="font-Inter text-base font-normal text-gray-400">
                  End Date
                </p>
                <p className="font-Inter font-normal text-sm">
                  {formattedEndDate}
                </p>
              </div>
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export default DemographicsPreview;

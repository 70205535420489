import React, { useState, useRef } from "react";
import { FiUploadCloud } from "react-icons/fi";
import { useController, Control } from "react-hook-form";
import FilePreview from "./FilePreview";
import CropModal from "./CropModal";
import { useToast } from "@/components/ui/use-toast";

interface FileDropzoneProps {
  label: string;
  accept: { [key: string]: string[] };
  maxFiles: number;
  maxSize?: number; // Optional max size
  preview?: boolean;
  required?: boolean;
  control: Control<any>;
  name: string;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({
  label,
  accept,
  maxFiles,
  maxSize = 5 * 1024 * 1024,
  preview = true,
  required = false,
  control,
  name,
}) => {
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { required: required && "File is required" },
  });

  const { toast } = useToast();
  const [dragging, setDragging] = useState(false);
  const [cropping, setCropping] = useState(false);
  const [uploadedImage, setUploadedImage] = useState<File | null>(null);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelection = (files: FileList | null) => {
    if (!files || files.length === 0) return;
  
    const selectedFiles = Array.from(files);
  
    if (selectedFiles.length > maxFiles) {
      toast({
        title: "Too many files",
        description: `You can only upload up to ${maxFiles} file(s).`,
        variant: "destructive",
      });
      return;
    }
  
    const file = selectedFiles[0];
    if (file.size > maxSize) {
      const fileSizeMB = (file.size / (1024 * 1024)).toFixed(2);
      toast({
        title: "File upload error",
        description: `File size (${fileSizeMB} MB) exceeds the limit.`,
        variant: "destructive",
      });
      return;
    }
  
    if (file.type.startsWith("image")) {
      setUploadedImage(file);
      setCropping(true); // Open cropping modal
    } else {
      onChange(file); // Directly accept non-image file types
    }
  
    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  

  const handleCropComplete = (croppedFile: File) => {
    onChange(croppedFile); // Update with cropped file
    setCropping(false);
    setUploadedImage(null);
  };

  // Handle delete file
  const handleRemoveFile = () => {
    // Remove file
    onChange(null);
  
    // Reset the file input value
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };
  

  const handleClick = (event: React.MouseEvent) => {
    if (value) {
      event.stopPropagation(); // Prevent opening file dialog if file is present
    } else if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="mb-4">
      <label className="block text-xs lg:text-sm lg:font-medium text-black dark:text-white mb-2 font-Inter">
        {label}
        {required && <span className="text-red-500"> *</span>}
      </label>
      <div
        onDragOver={(e) => {
          e.preventDefault();
          setDragging(true);
        }}
        onDragLeave={() => setDragging(false)}
        onDrop={(e) => {
          e.preventDefault();
          setDragging(false);
          handleFileSelection(e.dataTransfer.files);
        }}
        // onClick={() => fileInputRef.current?.click()}
        onClick={handleClick}
        className={`w-full ${value
          ? "border-none" // No padding or border when a file is uploaded
          : `p-6 border-2 border-dashed ${dragging ? 'border-blue-500' : 'border-gray-300'} dark:border-gray-800`
          } bg-white dark:bg-background rounded-md flex flex-col items-center cursor-pointer transition-colors duration-200`}
        aria-label={label}
      >
        <input
          type="file"
          ref={fileInputRef}
          accept={Object.keys(accept)
            .flatMap((key) => accept[key])
            .join(", ")}
          style={{ display: "none" }}
          onChange={(e) => handleFileSelection(e.target.files)}
          onBlur={onBlur}
        />
        {value ? (
          <div className="w-full">
            {preview && (
              <FilePreview
                file={value}
                onRemove={handleRemoveFile} // Call remove handler on delete icon click
              />
            )}
          </div>
        ) : (
          <>
            <FiUploadCloud className="text-3xl" />
            <p className="text-gray-500 text-center font-Inter font-normal text-sm">
              Tap to upload or drag and drop files
            </p>
            <p className="text-gray-500 text-center font-Inter font-normal text-sm">
              Accepted formats: {Object.keys(accept)
                .flatMap((key) => accept[key])
                .join(", ")}
            </p>
          </>
        )}
      </div>
      {error && (
        <p className="text-red-500 text-sm text-center mt-2 font-Inter">
          {error.message}
        </p>
      )}
      {cropping && uploadedImage && (
        <CropModal
          file={uploadedImage}
          onCropComplete={handleCropComplete}
          onCancel={() => setCropping(false)}
        />
      )}
    </div>
  );
};

export default FileDropzone;

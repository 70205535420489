import React, { useState, useEffect } from "react";
import { useFormContext, Controller, FieldValues, FieldError } from "react-hook-form";
import { X } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import useStore from "@/zustand/store";
import { useFetchData } from "@/hooks/useFetchData";  // Updated hook import

interface JamInterestsFieldProps {
  value?: string[];
}

const JamInterestsField: React.FC<JamInterestsFieldProps> = ({ value }) => {
  const { control, setValue, formState: { errors }, trigger } = useFormContext<FieldValues>();
  const [jamInterests, setJamInterests] = useState<string[]>(value || []);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [duplicateError, setDuplicateError] = useState<string>("");
  const setJamInterest = useStore((state) => state.setKeywords);

  const { data, isLoading, isError, error } = useFetchData();  // Using the combined hook
  // Extract interests and jamInterests from the data
  const fetchedJamInterests = data?.jamInterests || [];

  useEffect(() => {
    if (value) {
      setJamInterests(value);
    }
  }, [value]);

  const handleAddJamInterest = (jamInterest: string) => {
    if (jamInterest && jamInterests.length < 10) {
      if (jamInterests.includes(jamInterest)) {
        setDuplicateError(`"${jamInterest}" is already added.`);
      } else {
        const newJamInterests = [...jamInterests, jamInterest];
        setJamInterests(newJamInterests);
        setValue("jamInterests", newJamInterests);
        setJamInterest(newJamInterests);
        setSearchTerm("");
        trigger("jamInterests");
        setDuplicateError("");
      }
    }
  };

  const handleRemoveJamInterest = (index: number) => {
    const newJamInterests = jamInterests.filter((_, i) => i !== index);
    setJamInterests(newJamInterests);
    setValue("jamInterests", newJamInterests);
    trigger("jamInterests");
  };

  const jamInterestsError = errors.jamInterests as FieldError | undefined;
  const jamInterestsLimitReached = jamInterests.length >= 10;

  const filteredJamInterests = fetchedJamInterests
    ? fetchedJamInterests.filter((jamInterest) =>
      jamInterest.toLowerCase().includes(searchTerm.toLowerCase())
    )
    : [];

  return (
    <div className="flex flex-col mt-4 font-Inter">
      <Label htmlFor="jamInterests" className="font-semibold text-sm lg:text-base mb-1">
        Jam-Interests <span className="text-sm text-gray-400">(Optional)</span>
      </Label>

      <Controller
        name="jamInterests"
        control={control}
        render={() => (
          <div className="relative">
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setDuplicateError("");
              }}
              placeholder={
                jamInterestsLimitReached
                  ? "You can't add more Jam-Interests. Remove an existing one to update."
                  : "Type to search Jam-Interests"
              }
              className={`border p-2 rounded-md w-full ${isLoading || jamInterestsLimitReached
                ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                : ""
                }`}
              disabled={isLoading || isError || jamInterestsLimitReached}
            />

            {searchTerm && !jamInterestsLimitReached && (
              <ul className="absolute z-10 mt-2 w-full bg-white dark:bg-background border rounded-md shadow-lg max-h-48 overflow-y-auto">
                {isLoading ? (
                  <li className="p-2 text-gray-500">Loading...</li>
                ) : isError ? (
                  <li className="p-2 text-red-500">Error fetching Jam-Interests</li>
                ) : filteredJamInterests.length > 0 ? (
                  filteredJamInterests.map((jamInterest) => (
                    <li
                      key={jamInterest}
                      onClick={() =>
                        !jamInterests.includes(jamInterest) &&
                        handleAddJamInterest(jamInterest)
                      }
                      className={`p-2 ${jamInterests.includes(jamInterest)
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:bg-blue-500 hover:text-white cursor-pointer"
                        }`}
                    >
                      {jamInterest}{" "}
                      {jamInterests.includes(jamInterest) && (
                        <span className="text-xs">(Already added)</span>
                      )}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500 cursor-default">
                    This Jam-Interest is currently not available
                  </li>
                )}
              </ul>
            )}
          </div>
        )}
      />

      {duplicateError && <p className="text-red-500 text-sm pb-2 px-2">{duplicateError}</p>}
      {jamInterestsError?.message && (
        <p className="text-red-500 text-sm pb-2 px-2">{jamInterestsError.message}</p>
      )}

      {jamInterests.length > 0 && (
        <div className="mt-4">
          <h4 className="font-semibold text-sm lg:text-base">Added Jam-Interests:</h4>
          <ul className="list-none flex flex-wrap gap-2">
            {jamInterests.map((jamInterest, index) => (
              <li key={index} className="flex items-center space-x-2 text-sm">
                <span className="bg-gray-200 text-gray-800 py-1 px-4 rounded-full flex items-center space-x-2">
                  <span>{jamInterest}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveJamInterest(index)}
                    className="text-red-500 hover:text-red-700 p-1"
                  >
                    <X size={14} />
                  </button>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default JamInterestsField;

import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useFetchBudget } from "@/hooks/useFetchBudget";
import { Label } from "@/components/ui/label";
import { Info } from "lucide-react";
import useStore from "@/zustand/store";
import DatePickerWithRange from "./dateRangePicker";

const BudgetAndScheduleField: React.FC = () => {
  const {
    register,
    control,
    watch,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const dailyBudgetError = errors.dailyBudget;

  // Store setters
  const setDailyBudget = useStore((state) => state.setDailyBudget);
  const setStartDate = useStore((state) => state.setStartDate);
  const setEndDate = useStore((state) => state.setEndDate);

  // Fetch wallet balance using the custom hook
  const { data: walletBalance, error: fetchBudgetError } = useFetchBudget();

  // Watch the inputs for dailyBudget, startDate, and endDate
  const watchedDailyBudget = watch("dailyBudget");
  const watchedStartDate = watch("startDate");
  const watchedEndDate = watch("endDate");

  useEffect(() => {
    setDailyBudget(watchedDailyBudget || "0");
    setStartDate(watchedStartDate || "");
    setEndDate(watchedEndDate || "");
  }, [watchedDailyBudget, watchedStartDate, watchedEndDate, setDailyBudget, setStartDate, setEndDate]);

  // Calculate the estimated total spend based on the date range
  const calculateDateRange = (start: string | null, end: string | null): number => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diffTime = Math.abs(endDate.getTime() - startDate.getTime());
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
    }
    return 0;
  };

  const dailyBudgetNum = parseFloat(watchedDailyBudget || "0");
  const estimatedTotalSpend = calculateDateRange(watchedStartDate, watchedEndDate) * dailyBudgetNum;

  // Today's date (min date for picker)
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <div className="p-1 lg:p-4 space-y-4 lg:space-y-6 font-Inter">
      {/* Display Wallet Balance */}
      <div className="text-gray-700 font-semibold dark:text-white">
        Wallet Balance: ${walletBalance?.toFixed(2) || "0.00"}
      </div>
      {fetchBudgetError && (
        <p className="text-red-500 text-sm">Error: {fetchBudgetError.message}</p>
      )}

      {/* Budget and Date Range Section */}
      <div className="flex flex-col lg:flex-row gap-4">
        {/* Daily Budget Field */}
        <div className="flex flex-col relative lg:w-1/2">
          <div className="flex flex-row pb-2 items-center">
            <Label htmlFor="dailyBudget" className="block text-sm font-semibold">
              Enter Budget
            </Label>

            <span className="ml-2  hidden lg:block cursor-pointer group">
              <Info size={14} className="group-hover:cursor-default" />
              <div className="absolute left-[128px] -mt-9 items-center hidden group-hover:flex z-50">
                <div className="relative bg-gray-300 text-black border-2 border-gray-300 rounded-lg shadow-md p-4 max-w-xs w-[196px] h-[119px]">
                  <span className="text-sm">
                    Set the maximum daily Budget you want to Spend on this campaign.
                  </span>
                  <div className="absolute top-2 left-0 transform -translate-x-full mt-2 w-0 h-0 border-t-[10px] border-t-transparent border-b-[10px] border-b-transparent border-r-[15px] border-r-gray-300"></div>
                </div>
              </div>
            </span>
          </div>
          <div className="flex items-center border lg:w-full rounded-full bg-white dark:bg-background overflow-hidden">
            <span className="p-3 border-r-2 font-medium text-xs lg:text-base bg-gray-100 dark:bg-background">
              USD
            </span>
            <input
              id="dailyBudget"
              type="text"
              className="w-full px-2 py-2 bg-white text-sm lg:text-base focus:outline-none disabled:opacity-50 dark:bg-background"
              placeholder="enter daily budget"
              {...register("dailyBudget", {
                required: "Daily budget is required",
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: "Please enter a valid amount (e.g., 100.00)",
                },
                validate: (value) => {
                  // Check if the value is empty or just zeros
                  if (!value || value.trim() === "0" || value.trim() === "000" || parseFloat(value.trim()) === 0) {
                    return "Please enter a valid amount greater than 0";
                  }

                  // Parse the value and calculate estimated spend
                  const dailyBudgetValue = parseFloat(value.trim());

                  // Validate if it's a valid number
                  if (isNaN(dailyBudgetValue) || dailyBudgetValue <= 0) {
                    return "Please enter a valid amount (e.g., 100.00)";
                  }

                  const estimatedSpend = calculateDateRange(watchedStartDate, watchedEndDate) * dailyBudgetValue;

                  // Validate against wallet balance and estimated spend
                  if (dailyBudgetValue > (walletBalance || 0)) {
                    return "Daily budget exceeds wallet balance";
                  }
                  if (estimatedSpend > (walletBalance || 0)) {
                    return "Estimated total spend exceeds your wallet balance. Please adjust your budget or date range.";
                  }

                  return true; // Return true if everything is fine
                }

              })}
            />
          </div>
          {dailyBudgetError && typeof dailyBudgetError.message === 'string' && (
            <p className="text-red-500 text-sm pb-2 px-2">
              {dailyBudgetError.message}
            </p>
          )}

        </div>

        {/* Date Range Picker */}
        <div className="lg:w-1/2">
          <DatePickerWithRange
            control={control}
            watch={watch}
            errors={errors}
            setValue={setValue}
          />
        </div>
      </div>

      {/* Estimated Spend */}
      <div className="bg-gray-200 p-4 rounded-lg lg:w-96 mt-8 border-2 border-gray-200 font-Inter dark:bg-background ">
        <h4 className="mt-2 text-xs lg:text-sm font-medium lg:font-semibold">
          Estimated Daily Spend: {`$${dailyBudgetNum.toFixed(2)}`}
        </h4>
        <h4 className="mt-2 text-xs lg:text-sm font-medium lg:font-semibold">
          Estimated Total Spend: {`$${estimatedTotalSpend.toFixed(2)}`}
        </h4>
      </div>
    </div>
  );
};

export default BudgetAndScheduleField;

import React from "react";
import { useFormContext } from "react-hook-form";
import FileDropzone from "../createAd/fileAdzone";
import { Card, CardContent } from "@/components/ui/card";
import Preview from "../createAd/previewScreen";
import { Separator } from "@/components/ui/separator";
import { FormData } from "@/components/types/internal/types/FormDataTypes";

/**
 * Component to add audio to an advertisement.
 * @returns JSX.Element
 */
const AddAudio: React.FC = () => {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<FormData>();
  const audioFile = watch("adAudio");

  /**
   * Handler for audio file change.
   * @param file - The new audio file.
   */
  const handleAudioFileChange = (file: File | null): void => {
    setValue("adAudio", file, { shouldValidate: true }); // Update form state with the new audio file and trigger validation
  };

  return (
    <div className="bg-gray-50 flex flex-col md:flex-row lg:flex-row justify-center md:gap-2 lg:items-start gap-10 lg:gap-20 p-2 px-4 py-14 lg:py-1 overflow-hidden dark:bg-background">
      <Card className="bg-gray-100 p-8 rounded-lg shadow-lg lg:w-[800px] dark:bg-background border border-gray-300">
        <h3 className="text-xl lg:text-2xl font-semibold font-Inter pl-6 lg:pb-2 ">
          Add Audio
        </h3>

        <CardContent>
          <Separator className="my-2 bg-gray-400" />
          <FileDropzone
            label="Upload audio file"
            accept={{
              "audio/mpeg": [".mp3"],  // Accept MP3 audio files
              "audio/wav": [".wav"],   // Accept WAV audio files
            }}
            maxFiles={1}
            control={control}
            name="adAudio"
            required
          />
        </CardContent>
      </Card>
      <Preview />
    </div>
  );
};

export default AddAudio;

import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import CreateAd from "../createAd";
import { useMultiStep } from "@/hooks/useMultiStep";
import Customize from "../CustomizeDelivery";
import AddAudio from "../addAudio";
import StepIndicator from "../stepIndicator";
import { CardFooter } from "@/components/ui/card";
import { FormData, INITIAL_FORM_DATA } from "@/components/types/internal/types/FormDataTypes";
import Objective from "../objective";
import useStore from "@/zustand/store";
import { toast } from "react-toastify";
import usePageUnloadWarning from "@/hooks/usePageUnloadWarning";
import PopoverComponent from "./VerificationPopover";
import { Button } from "@/components/ui/button";

const steps = [
  <Objective key="objective-step" />,
  <Customize key="customize-step" />,
  <CreateAd key="create-ad-step" />,
  <AddAudio key="add-audio-step" />,
];

const stepLabels = ["Create Ad", "Add audio", "Customize delivery"];

const MultiStep: React.FC = () => {
  const methods = useForm<FormData>({
    defaultValues: INITIAL_FORM_DATA,
    mode: "onTouched",
  });

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = methods;

  const resetAdState = useStore((state) => state.resetAdState);

  const { submitAd, submitStatus, submitError } = useStore((state) => ({
    submitAd: state.submitAd,
    submitStatus: state.submitStatus,
    submitError: state.submitError,
  }));

  const [notificationTriggered, setNotificationTriggered] = useState<boolean>(false);
  const [isPopoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>(false);

  const { currentStepIndex, step, isFirstStep, back, next, isLastStep } =
    useMultiStep(steps);

  // Use the custom hook to handle page unload warning, passing isFormSubmitted
  usePageUnloadWarning(isDirty && !isFormSubmitted);

  // Scroll to top whenever the step changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStepIndex]);

  // Handle toast notifications based on submitStatus changes
  useEffect(() => {
    if (notificationTriggered) return;

    if (submitStatus === "success") {
      toast.success(
        <div>
          <p>Ad created successfully!</p>
        </div>
      );
      setNotificationTriggered(true);
      setPopoverOpen(true);
      setIsFormSubmitted(true);  // Set form submission flag to true
      reset(INITIAL_FORM_DATA);
      resetAdState();
    } else if (submitStatus === "error") {
      toast.error(submitError || "Failed to submit data");
      setNotificationTriggered(true);
    }
  }, [submitStatus, submitError, notificationTriggered]);

  const onSubmit = async (data: FormData) => {
    if (!isLastStep) {
      next();
    } else {
      setNotificationTriggered(false);
      console.log(data);
      await submitAd(data);
    }
  };

  return (
    <div className="relative min-h-screen pb-14 lg:pb-17 dark:bg-background">
      {currentStepIndex > 0 && (
        <div className="absolute top-0 left-0 right-0">
          <StepIndicator
            currentStepIndex={currentStepIndex - 1}
            totalSteps={stepLabels.length}
          />
        </div>
      )}

      <div className="lg:pt-16">
        <FormProvider {...methods}>{step}</FormProvider>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardFooter className="fixed bottom-0 left-0 right-0 bg-gray-300 py-2 lg:px-20 dark:bg-background">
          <div className="flex justify-between items-center w-full">
            <div className="flex-grow">
              {!isFirstStep && (
                <Button
                  variant="secondary"
                  type="button"
                  onClick={back}
                  className="w-24 lg:w-36"
                >
                  Back
                </Button>
              )}
            </div>
            <Button
              variant="primary"
              type="submit"
              className={` text-black w-24 lg:w-36 ${!watch("selectedLeadCardTitle") ? "opacity-50 cursor-not-allowed" : ""}`}
              disabled={
                !watch("selectedLeadCardTitle") ||
                submitStatus === "loading"
              }
            >
              {submitStatus === "loading"
                ? "Submitting..."
                : isLastStep
                  ? "Finish"
                  : "Next"}
            </Button>
          </div>
        </CardFooter>
      </form>
      {/* Popover Component */}
      <PopoverComponent isOpen={isPopoverOpen} onClose={() => setPopoverOpen(false)} />
    </div>
  );
};

export default MultiStep;

import React, { useState, useEffect } from "react";
import { useFormContext, Controller, FieldValues, FieldError } from "react-hook-form";
import { X } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import useStore from "@/zustand/store";
import { useFetchData } from "@/hooks/useFetchData";

interface InterestsFieldProps {
  value?: string[];
}

const InterestsField: React.FC<InterestsFieldProps> = ({ value }) => {
  const { control, setValue, formState: { errors }, trigger } = useFormContext<FieldValues>();
  const [interests, setInterests] = useState<string[]>(value || []);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [duplicateError, setDuplicateError] = useState<string>("");
  const setInterest = useStore((state) => state.setInterests);

  // Use the unified fetch data hook to get both interests and jamInterests
  const { data: fetchedData, isLoading, error } = useFetchData();
  

  useEffect(() => {
    if (value) {
      setInterests(value);
    }
  }, [value]);

  const handleAddInterest = (interest: string) => {
    if (interest && interests.length < 10) {
      if (interests.includes(interest)) {
        setDuplicateError(`"${interest}" is already in your interests.`);
      } else {
        const newInterests = [...interests, interest];
        setInterests(newInterests);
        setValue("interests", newInterests);
        setInterest(newInterests);
        setSearchTerm("");
        trigger("interests");
        setDuplicateError("");
      }
    }
  };

  const handleRemoveInterest = (index: number) => {
    const newInterests = interests.filter((_, i) => i !== index);
    setInterests(newInterests);
    setValue("interests", newInterests);
    trigger("interests");
  };

  const interestsError = errors.interests as FieldError | undefined;
  const isError = error instanceof Error;
  const interestLimitReached = interests.length >= 10;

  // Combine both interests and jamInterests from the fetched data
  const combinedInterests = fetchedData ? [...fetchedData.interests, ...fetchedData.jamInterests] : [];

  // Compute the filtered interests based on the search term
  const filteredInterests = combinedInterests
    .filter((interest) => interest.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <div className="flex flex-col mt-4 font-Inter">
      <Label htmlFor="interests" className="font-semibold text-sm lg:text-base mb-1">
        Interests <span className="text-sm text-gray-400">(Optional)</span>
      </Label>

      {/* Input field for entering interests */}
      <Controller
        name="interests"
        control={control}
        render={() => (
          <div className="relative">
            <Input
              type="text"
              value={searchTerm}
              onChange={(e) => {
                setSearchTerm(e.target.value);
                setDuplicateError("");
              }}
              placeholder={
                interestLimitReached
                  ? "You can't add more interests. Remove an existing one to update."
                  : "Type to search interests"
              }
              className={`border p-2 rounded-md w-full ${isLoading || interestLimitReached
                ? "bg-gray-100 text-gray-500 cursor-not-allowed"
                : ""
                }`}
              disabled={isLoading || isError || interestLimitReached} // Disable when loading, error, or limit is reached
            />

            {/* Show suggestions or no-match message */}
            {searchTerm && !interestLimitReached && !isLoading && (
              <ul className="absolute z-10 w-full bg-white dark:bg-background mt-2 border rounded-md shadow-lg max-h-48 overflow-y-auto">
                {isError ? (
                  <li className="p-2 text-red-500">Error fetching interests</li>
                ) : filteredInterests.length > 0 ? (
                  filteredInterests.map((interest) => (
                    <li
                      key={interest}
                      onClick={() =>
                        !interests.includes(interest) && handleAddInterest(interest)
                      }
                      className={`p-2 cursor-pointer ${interests.includes(interest)
                        ? "text-gray-400 cursor-not-allowed"
                        : "hover:bg-blue-500 hover:text-white"
                        }`}
                    >
                      {interest} {interests.includes(interest) && <span className="text-xs">(Already added)</span>}
                    </li>
                  ))
                ) : (
                  <li className="p-2 text-gray-500 cursor-default">
                    This Interest is currently not available
                  </li>
                )}
              </ul>
            )}
          </div>
        )}
      />

      {/* Display duplicate error message */}
      {duplicateError && <p className="text-red-500 text-sm pb-2 px-2">{duplicateError}</p>}

      {/* Display validation error message */}
      {interestsError?.message && (
        <p className="text-red-500 text-sm pb-2 px-2">{interestsError.message}</p>
      )}

      {/* Display list of added interests with Remove button */}
      {interests.length > 0 && (
        <div className="mt-4">
          <h4 className="font-semibold text-sm lg:text-base">Added Interests:</h4>
          <ul className="list-none flex flex-wrap gap-2">
            {interests.map((interest, index) => (
              <li key={index} className="flex items-center space-x-2 text-sm">
                <span className="bg-gray-200 text-gray-800 py-1 px-4 rounded-full flex items-center space-x-2">
                  <span>{interest}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveInterest(index)}
                    className="text-red-500 hover:text-red-700 p-1"
                  >
                    <X size={14} />
                  </button>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default InterestsField;

import { StateCreator } from "zustand";

// Extend the AdState interface to include startDate and endDate
export interface AdState {
  adText: string;
  discountCode: string;
  adImage: File | null;
  backgroundMedia: File | null;
  adAudio: File | null;
  countries: string[];
  gender: string | null;
  age: string;
  location: string[];
  keywords: string[]; // Change from string to string[]
  interests: string[]; // Change from string to string[]
  language: string[]; // Change from string to string[]
  dailyBudget: string;
  dateRange: string | null;
  startDate: string | null; // New field for start date
  endDate: string | null; // New field for end date
  targetingDevice: string;
  setAdText: (text: string) => void;
  setDiscountCode: (code: string) => void;
  setAdImage: (file: File | null) => void;
  setBackgroundMedia: (file: File | null) => void;
  setCtaButtonTitle: (text: string) => void;
  setAdAudio: (file: File | null) => void;
  setCountries: (countries: string[]) => void;
  setGender: (gender: string | null) => void;
  setAge: (age: string) => void;
  setLocation: (locations: string[]) => void;
  setKeywords: (keywords: string[]) => void; // Update to handle string[] instead of string
  setInterests: (interests: string[]) => void; // Update to handle string[] instead of string
  setLanguage: (language: string[]) => void; // Update to handle string[] instead of string
  setDailyBudget: (budget: string) => void;
  setDateRange: (range: string | null) => void;
  setStartDate: (date: string | null) => void; // New setter for start date
  setEndDate: (date: string | null) => void; // New setter for end date
  setTargetingDevice: (device: string) => void;
  resetAdState: () => void; // Function to reset state
}

// Update createAdSlice to handle startDate and endDate
export const createAdSlice: StateCreator<AdState> = (set) => ({
  adText: "",
  discountCode: "",
  adImage: null,
  backgroundMedia: null,
  adAudio: null,
  countries: [],
  gender: null,
  age: "all",
  location: [],
  keywords: [], // Change to an empty array (string[])
  interests: [], // Change to an empty array (string[])
  language: [], // Change to an empty array (string[])
  dailyBudget: "",
  dateRange: null,
  startDate: null, // Initial value for start date
  endDate: null, // Initial value for end date
  targetingDevice: "",
  setAdText: (text: string) => set({ adText: text }),
  setDiscountCode: (code: string) => set({ discountCode: code }),
  setAdImage: (file: File | null) => set({ adImage: file }),
  setBackgroundMedia: (file: File | null) => set({ backgroundMedia: file }),
  setCtaButtonTitle: (text: string) => set({ adText: text }),
  setAdAudio: (file: File | null) => set({ adAudio: file }),
  setCountries: (countries: string[]) => set({ countries }),
  setGender: (gender: string | null) => set({ gender }),
  setAge: (age: string) => set({ age }),
  setLocation: (locations: string[]) => set({ location: locations }),
  setKeywords: (keywords: string[]) => set({ keywords }), // Update setter to accept string[] instead of string
  setInterests: (interests: string[]) => set({ interests }), // Update setter to accept string[] instead of string
  setLanguage: (language: string[]) => set({ language }), // Update setter to accept string[] instead of string
  setDailyBudget: (budget: string) => set({ dailyBudget: budget }),
  setDateRange: (range: string | null) => set({ dateRange: range }),
  setStartDate: (date: string | null) => set({ startDate: date }), // Setter for start date
  setEndDate: (date: string | null) => set({ endDate: date }), // Setter for end date
  setTargetingDevice: (device: string) => set({ targetingDevice: device }),
  resetAdState: () =>
    set({
      adText: "",
      discountCode: "",
      adImage: null,
      backgroundMedia: null,
      adAudio: null,
      countries: [],
      gender: null,
      age: "all",
      location: [],
      keywords: [], // Reset to empty array (string[])
      interests: [], // Reset to empty array (string[])
      language: [], // Reset to empty array (string[])
      dailyBudget: "",
      dateRange: null,
      startDate: null,
      endDate: null,
      targetingDevice: "",
    }),
});

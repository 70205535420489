import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { ModeToggle } from "../dark&light-Mode/theme-toggle";
import useStore from "@/zustand/store";
import { useState } from "react";
import UserNav from "../userNav";
import { AiOutlineMenu } from "react-icons/ai";
import Sidebar from "../sideBar";
import JammeLogo from "@/assets/jammeLogo.svg";
import JammeDarkLogo from "@/assets/jammeDarkLogo.svg";
import { useThemeStore } from "@/store";
import NavigationMenuDemo from "../NavigationMenu/NavigationMenu";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next"; // Import useTranslation

interface HeaderProps {
  isHomeScreen: boolean;
}

function Header({ isHomeScreen }: HeaderProps) {
  const { t } = useTranslation('header'); // Use the header namespace
  const user = useStore((state) => state.user);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const closeSidebar = () => setSidebarOpen(false);
  const { theme } = useThemeStore();
  const logoSrc = theme === "dark" ? JammeDarkLogo : JammeLogo;

  return (
    <div className="relative">
      <header
        className={`fixed top-0 w-full h-20 z-50 flex items-center justify-between p-2 lg:px-8 lg:py-2 ${isHomeScreen
          ? "bg-gradient-to-l from-[#FFC26F80] to-[#FFC26F] dark:from-[#000000] dark:to-[#000000] backdrop-blur-[100px]"
          : "bg-gray-50 dark:bg-background"
          }`}
      >
        <div className="flex items-center gap-4">
          <Link to="/" className="flex items-center gap-4">
            <motion.div
              className="h-12 w-12 hidden lg:block md:block items-center justify-center rounded-full"
              animate={{
                scale: [1, 1.1, 0.95, 1.1, 1], // Subtle scaling
              }}
              transition={{
                duration: 4, // Increase duration for slower animation
                ease: [0.25, 0.46, 0.45, 0.94], // Smooth easing for natural movement
                repeat: Infinity, // Loop indefinitely
                repeatType: "mirror", // Ensure the animation reverses smoothly
              }}
            >
              <img
                src={logoSrc}
                alt="Jamme Logo"
                loading="lazy"
                className="h-11 w-11"
              />
            </motion.div>
            <h3 className="hidden lg:block lg:text-2xl text-foreground dark:text-foreground font-Inter font-semibold cursor-pointer">
              {t('companyName')}
            </h3>
          </Link>
          {user && (
            <div className="hidden lg:flex items-center gap-2 ml-4">
              <Link to="/dashboard">
                <Button
                  variant="default"
                  className={`p-4 rounded-3xl font-Inter font-medium ${isHomeScreen
                    ? "bg-orange150 text-black border-2 border-black dark:border-white dark:text-white hover:bg-orange-150 hover:scale-105 transition-transform transform ease-in-out duration-300"
                    : "bg-[#FFEFE0] hover:bg-Primary text-black"
                    }`}
                >
                  {t('analyticsButton')}
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className="ml-4 flex items-center gap-4 md:ml-6 lg:hidden">
          <ModeToggle />
          {/* Conditionally render UserNav for small screens */}
          {user && <UserNav />}
          <button
            aria-label="Open menu"
            className="p-2 rounded-md text-black"
            onClick={() => setSidebarOpen(true)}
          >
            <AiOutlineMenu className="w-6 h-6 dark:text-white" />
          </button>
        </div>
        <div className="ml-4 hidden lg:flex items-center gap-4 md:ml-6">
          {user ? (
            <>
              <NavigationMenuDemo />
              <Link to="/ads">
                <Button
                  className={`font-Inter rounded-full ${isHomeScreen
                    ? "bg-orange150 text-black border-2 border-black dark:border-white dark:text-white hover:bg-orange-150 hover:scale-105 transition-transform transform ease-in-out duration-300"
                    : "bg-Primary hover:bg-Primary text-black"
                    }`}
                >
                  {t('launchAdButton')}
                </Button>
              </Link>
              <UserNav />
            </>
          ) : (
            <>
              <NavigationMenuDemo />
              <DropdownMenu modal={false}>
                <DropdownMenuTrigger>
                  <Button
                    className={`border-2 ${isHomeScreen ? "border-black dark:border-Primary hover:dark:bg-black" : "border-Primary"
                      } p-3 px-6 rounded-full text-black dark:text-white font-Inter font-semibold ${isHomeScreen
                        ? "bg-orange-150 hover:bg-orange-200 hover:scale-105 transition-transform transform ease-in-out duration-300"
                        : "bg-Primary dark:bg-black hover:scale-105 transition-transform transform ease-in-out duration-300 ml-2 hover:bg-white"
                      }`}
                  >
                    {t('loginButton')}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="mt-2 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-md shadow-lg font-Inter font-bold">
                  <DropdownMenuItem className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200">
                    <Link
                      to="/login"
                      className="block px-4 py-2 text-sm text-black dark:text-white"
                    >
                      {t('signInAdmin')}
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem className="hover:bg-gray-100 dark:hover:bg-gray-700 transition-colors duration-200">
                    <Link
                      to="/orglogin"
                      className="block px-4 py-2 text-sm text-black dark:text-white"
                    >
                      {t('loginAsViewerEditor')}
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
              <Link to="/register">
                <Button
                  variant="outline"
                  className={`border-2 ${isHomeScreen ? "border-black dark:border-Primary hover:dark:bg-black" : "border-Primary"
                    } p-3 px-6 rounded-full text-black dark:text-white font-Inter font-semibold ${isHomeScreen
                      ? "bg-orange-150 hover:bg-orange-200 hover:scale-105 transition-transform transform ease-in-out duration-300"
                      : "bg-Primary dark:bg-black hover:scale-105 transition-transform transform ease-in-out duration-300 ml-2"
                    }`}
                >
                  {t('launchAdButton')}
                </Button>
              </Link>
            </>
          )}
          <ModeToggle />
        </div>
      </header>
      <Sidebar isOpen={sidebarOpen} onClose={closeSidebar} user={user} />
    </div>
  );
}

export default Header;

import React from "react";
import { useFormContext } from "react-hook-form";
import SimpleLeadCard from "../cards";
import { FormData } from "@/components/types/internal/types/FormDataTypes";

const Objective: React.FC = () => {
  const { setValue, watch } = useFormContext<FormData>();
  const selectedLeadCardTitle = watch('selectedLeadCardTitle');

  const handleCardSelect = (title: string) => {
    setValue('selectedLeadCardTitle', title);
  };

  return (
    <section className="bg-gray-50 lg:fixed lg:inset-0 lg:bottom-14 flex items-center justify-center dark:bg-background">
      <div className="flex flex-col items-center justify-center gap-5">
        <h1 className="pt-10 lg:pt-0 lg:text-2xl font-Inter font-bold">
          What is your advertising goal?
        </h1>
        <div className="flex flex-1 items-center justify-center gap-8 py-4 flex-col lg:flex-row font-Inter">
          <SimpleLeadCard
            onCardSelect={handleCardSelect}
            selectedCardTitle={selectedLeadCardTitle} // Pass as string | null
          />
        </div>
      </div>
    </section>
  );
};

export default Objective;

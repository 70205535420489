import React, { useMemo, useEffect } from "react";
import { AiOutlineHeart, AiOutlineShareAlt } from "react-icons/ai";
import { BsBookmark } from "react-icons/bs";
import soundWave from "@/assets/Soundwave big.png";
import StatusBar from "@/assets/Time.png";
import useStore from "@/zustand/store";
import battery from "@/assets/Battery.png";
import Cellular from "@/assets/Cellular Connection.png";
import Wifi from "@/assets/Wifi.png";

const Preview: React.FC = () => {
  const { adText, adImage, backgroundMedia } = useStore();

  // Memoize the object URL creation to avoid unnecessary re-renders
  const adImageURL = useMemo(() => (adImage ? URL.createObjectURL(adImage) : ""), [adImage]);
  const backgroundMediaURL = useMemo(() => (backgroundMedia ? URL.createObjectURL(backgroundMedia) : ""), [backgroundMedia]);

  // Clean up the object URLs on unmount
  // useEffect(() => {
  //   return () => {
  //     if (adImageURL) URL.revokeObjectURL(adImageURL);
  //     if (backgroundMediaURL) URL.revokeObjectURL(backgroundMediaURL);
  //   };
  // }, [adImageURL, backgroundMediaURL]);

  return (
    <div className="relative flex items-center justify-center border border-gray-300 rounded-3xl">
      {/* Video Background */}
      {backgroundMedia && backgroundMedia.type.startsWith("video") && (
        <video
          src={backgroundMediaURL}
          autoPlay
          muted
          loop
          className="absolute top-0 left-0 w-full h-full object-cover rounded-3xl"
        />
      )}

      {/* Image Background */}
      {backgroundMedia && backgroundMedia.type.startsWith("image") && (
        <div
          className="absolute top-0 left-0 w-full h-full bg-cover bg-center rounded-3xl"
          style={{ backgroundImage: `url(${backgroundMediaURL})` }}
        />
      )}

      {/* Gradient Overlay */}
      <div className="absolute top-0 left-0 w-full h-full bg-gradient-overlay rounded-3xl" />

      {/* Main Content */}
      <div className="relative w-[350px] lg:w-[350px] overflow-hidden font-Chillax">
        <div
          className="text-white flex flex-col items-center p-4 rounded-3xl"
          style={{
            backgroundImage: `
              linear-gradient(179.98deg, rgba(13, 6, 0, 0.09) 0.01%, rgba(20, 10, 0, 0.69) 19.42%, rgba(18, 9, 0, 0.84) 56.86%, #100800 99.99%),
  linear-gradient(to bottom, rgba(34, 34, 34, 0.5), rgba(240, 185, 11, 0.5))
            `,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="flex items-center justify-between gap-[160px] lg:gap-[220px] pb-5 overflow-hidden">
            <img src={StatusBar} alt="Status bar" />
            <div className="flex gap-1 w-20">
              <img src={Cellular} alt="Cellular connection" />
              <img src={Wifi} alt="Wifi" />
              <img src={battery} alt="Battery" />
            </div>
          </div>
          <div className="flex items-center w-[340px] lg:w-[360px] mb-2 px-10">
            <button className="text-white text-xl mr-auto">&larr;</button>
            <h1 className="text-sm mx-auto">Ads</h1>
            <button className="text-white text-xl ml-auto">&bull;&bull;&bull;</button>
          </div>

          <p className="mt-4 text-sm text-center tracking-tighter">
            Listen without skipping for <br /> 10 coins
          </p>

          <div className="bg-[#FFD7B1] rounded-xl w-[200px] h-96 mt-4">
            {adImage && adImage.type.startsWith("image") && (
              <img
                src={adImageURL}
                alt="Ad"
                className="object-cover h-full w-full rounded-xl"
              />
            )}
            {adImage && adImage.type.startsWith("video") && (
              <video autoPlay muted loop className="object-cover h-full w-full rounded-xl">
                <source src={adImageURL} type={adImage.type} />
                Your browser does not support the video element.
              </video>
            )}
            <div className="flex flex-col items-center mt-4 space-y-4 relative left-[122px] lg:left-[140px] top-[230px]">
              <div className="flex flex-col items-center">
                <AiOutlineHeart size={24} className="text-white" />
                <span className="text-xs text-white">12.3k</span>
              </div>
              <BsBookmark size={24} className="text-white" />
              <AiOutlineShareAlt size={24} className="text-white" />
            </div>
          </div>

          <div className="bg-Primary rounded-full w-64 lg:w-80 h-10 mt-8 flex items-center justify-center text-black font-medium">
            <span>{adText || "----"}</span>
          </div>
          <div className="w-full mt-4 px-6">
            <div className="w-full h-8 to-amber-900 rounded-lg">
              <img src={soundWave} alt="Sound wave" />
            </div>
          </div>

          <button className="mt-6 text-sm text-white">Skip ad</button>
        </div>
      </div>
    </div>
  );
};

export default Preview;

import { Area } from "react-easy-crop";

/**
 * Creates an HTMLImageElement from a given URL.
 * @param url - Source URL of the image.
 * @returns A promise that resolves to an HTMLImageElement.
 */
export const createImage = (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = url;
        image.crossOrigin = "anonymous"; // To handle CORS issues
        image.onload = () => resolve(image);
        image.onerror = () => reject(new Error(`Failed to load image at ${url}`));
    });
};

/**
 * Crops an image based on given cropping details and applies rotation if specified.
 * @param imageSrc - Source URL of the image.
 * @param croppedAreaPixels - Object defining the cropping area in pixels.
 * @param rotation - Rotation angle in degrees (default is 0).
 * @returns A promise that resolves to a Blob of the cropped image.
 */
export const getCroppedImg = async (
    imageSrc: string,
    croppedAreaPixels: Area,
    rotation: number = 0
): Promise<Blob> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
        throw new Error("Failed to get 2D context from canvas");
    }

    // Set canvas size to match the cropped area
    const { width, height } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;

    // Translate canvas to the center of the cropped area for proper rotation
    ctx.translate(width / 2, height / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-width / 2, -height / 2);

    // Draw the cropped area onto the canvas
    ctx.drawImage(
        image,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        width,
        height,
        0,
        0,
        width,
        height
    );

    // Convert the canvas content to a Blob
    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                if (blob) {
                    resolve(blob);
                } else {
                    reject(new Error("Failed to create blob from canvas"));
                }
            },
            "image/jpeg",
            1.0 // Set image quality to maximum (optional, depends on requirements)
        );
    });
};

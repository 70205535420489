export type FormData = {
  selectedLeadCardTitle: string | null;
  adImage: File | null;
  backgroundImage: File | null;
  ctaButtonTitle: string;
  buttonUrl: string;
  adText: string;
  discountCode: string;
  adAudio: File | null;
  gender: string | null;
  age: string;
  location: string[];
  jamInterests: string [];
  interests: string [];
  language: string[];
  dailyBudget: string | null;
  adScheduleTime: string;
  startDate:string
  endDate: string;
  adName:string;
  targetingDevices: string[];
  iosLink:string;
  androidLink:string;
};
// Initialize the form data
 export const INITIAL_FORM_DATA: FormData = {
  adName: "",
  adImage: null,
  backgroundImage: null,
  adAudio: null,
  ctaButtonTitle: "",
  buttonUrl: "",
  adText: "",
  discountCode: "",
  gender: null,
  age: "",
  location: [],
  jamInterests: [],
  interests: [],
  language: [],
  dailyBudget: "",
  adScheduleTime: "",
  selectedLeadCardTitle: null,
  startDate: "",
  endDate: "",
  targetingDevices: [],
  iosLink:"",
  androidLink:""
};

// types.ts
// Mocked locations list
export const mockLocations = [
  "New York", "Los Angeles", "Chicago", "Houston", "Phoenix",
  "Mumbai", "Delhi", "Bangalore", "Hyderabad", "Chennai", "Kolkata", "Pune",
  "London", "Birmingham", "Manchester", "Glasgow", "Leeds",
  "Toronto", "Vancouver", "Montreal", "Calgary", "Ottawa",
  "Sydney", "Melbourne", "Brisbane", "Perth", "Adelaide",
  "Berlin", "Munich", "Frankfurt", "Hamburg", "Cologne",
  "Paris", "Marseille", "Lyon", "Toulouse", "Nice",
  "Beijing", "Shanghai", "Shenzhen", "Guangzhou", "Chengdu",
  "Tokyo", "Osaka", "Yokohama", "Nagoya", "Sapporo",
  "São Paulo", "Rio de Janeiro", "Brasilia", "Salvador", "Fortaleza",
  "Johannesburg", "Cape Town", "Durban", "Pretoria", "Port Elizabeth",
  "Dubai", "Abu Dhabi", "Riyadh", "Jeddah", "Doha",
  "Rome", "Madrid", "Athens", "Vienna", "Zurich",
  "Karachi", "Dhaka", "Kathmandu", "Colombo", "Islamabad",
  "Mexico City", "Buenos Aires", "Lima", "Santiago", "Bogota",
  "Cairo", "Nairobi", "Lagos", "Accra", "Kampala",
  "Bangkok", "Jakarta", "Manila", "Kuala Lumpur", "Singapore",
  "Moscow", "Seoul", "Istanbul", "Helsinki", "Stockholm"
];
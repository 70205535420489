import React from 'react';
import {
    AlertDialog,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogDescription,
} from '@/components/ui/alert-dialog';
import { Separator } from '@/components/ui/separator';
interface PopoverComponentProps {
    isOpen: boolean;
    onClose: () => void;
}

const PopoverComponent: React.FC<PopoverComponentProps> = ({ isOpen, onClose }) => {
    const handleCreateMoreAds = () => {
        onClose(); // Close the popover
        window.location.reload(); // Refresh the page
    };

    const handleGoToDashboard = () => {
        onClose(); // Close the popover
        window.location.reload(); // Refresh the dashboard
        window.location.href = "/dashboard"; // Navigate to the dashboard
    };

    return (
        <AlertDialog open={isOpen}>
            <AlertDialogContent
                className="font-Inter"
                onClick={(e) => e.stopPropagation()} // Prevent closing on clicking outside
            >
                <AlertDialogHeader>
                    <AlertDialogTitle className="text-2xl font-semibold text-black dark:text-white">
                        Verification is underway
                    </AlertDialogTitle>
                    <AlertDialogDescription className="mt-2 text-gray-500">
                        To maintain the quality of ads on Jamme, we are first going to verify your
                        account before your campaign will start running.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <Separator />
                <div className="mt-2 pt-2">
                    <h2 className="text-lg font-semibold text-black dark:text-white">
                        Your campaign is pending
                    </h2>
                    <p className="mt-2 text-gray-500">
                        Your ad campaign and the account will be in a “pending verification” state
                        until we finish reviewing them.
                    </p>
                </div>
                <div className="mt-6">
                    <h2 className="text-lg font-semibold text-black dark:text-white">
                        No action needed from you
                    </h2>
                    <p className="mt-2 text-gray-500">
                        We will complete verification in 24 - 48 hours.
                    </p>
                </div>
                <div className="mt-6">
                    <h2 className="text-lg font-semibold text-black dark:text-white">
                        Your campaign will be running without any further input
                    </h2>
                    <p className="mt-2 text-gray-500">
                        Your campaign will be able to serve without any further steps from you once
                        verification is complete.
                    </p>
                </div>
                <div className='flex flex-row items-center justify-center pt-4 gap-4'>
                    <button
                        onClick={handleCreateMoreAds} // Calls the function to close and refresh
                        className="text-black w-full font-medium border-black bg-Primary rounded-full hover:bg-Primary mr-4 inline-block py-2 px-4 hover:scale-105 transition-transform transform ease-in-out duration-300"
                    >
                        Create More Ads
                    </button>
                    <button
                        onClick={handleGoToDashboard}
                        className="text-black font-medium w-full border-black bg-Primary rounded-full hover:bg-Primary mr-4 inline-block py-2 px-4 hover:scale-105 transition-transform transform ease-in-out duration-300"
                    >
                        Go to Dashboard
                    </button>
                </div>
            </AlertDialogContent>
        </AlertDialog>
    );
};

export default PopoverComponent;

import { useState, useEffect, useRef } from "react";
import { Card, CardHeader, CardContent } from "@/components/ui/card";
import MainImage from "@/assets/Frame 185315 (2).svg";
import JammeLogo from "@/assets/jammeLogo.svg";
import JammeDarklogo from "@/assets/jammeDarkLogo.svg";
import { useThemeStore } from "@/store";
import OrganizationForm from "../ProfileForm/ProfileDataForm";
import StepIndicator from "../../StepIndicator/StepIndicator";

const ProfileScreen = () => {
  const { theme } = useThemeStore();
  const logoSrc = theme === "dark" ? JammeDarklogo : JammeLogo;

  // To store the height of the Card (OrganizationForm)
  const [cardHeight, setCardHeight] = useState<number>(0);

  // Ref with proper type HTMLDivElement
  const cardRef = useRef<HTMLDivElement>(null); // Reference for the Card component

  // Dynamically set the height of the main image based on the Card height
  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.offsetHeight);
    }
  }, [cardRef]);

  return (
    <div className="flex flex-row justify-center xl:pr-24 md:gap-8 sm:gap-8 lg:gap-8 xl:gap-0 md:p-4 -mt-20 lg:-mt-14 lg:p-2 xl:p-0 overflow-hidden bg-gray-50 dark:bg-background ">
      <div className="hidden sm:hidden md:hidden lg:block">
        <img
          src={MainImage}
          alt="Main visual"
          className="xl:w-[740px]"
          style={{ height: cardHeight }} // Dynamic height based on Card
        />
      </div>
      <div className="flex justify-center bg-white dark:bg-background w-full lg:w-[620px] xl:w-[520px] rounded-sm md:rounded-2xl lg:rounded-3xl border lg:dark:border-white">
        <Card
          ref={cardRef} // Attach the ref to the Card
          className="lg:h-auto w-[300px] md:w-96 lg:w-auto rounded-sm md:rounded-2xl lg:rounded-3xl dark:bg-background bg-white"
        >
          <div className="md:block lg:block md:px-4 lg:px-0 mt-8">
            <StepIndicator currentStep={2} />
          </div>
          <CardHeader className="flex flex-col items-center">
            <img src={logoSrc} alt="Jamme Logo" className="w-12 h-12 mb-4" loading="lazy" />
            <h3 className="font-Inter font-semibold text-xl md:text-2xl lg:text-2xl tracking-tighter text-center mb-4 whitespace-nowrap">
              Where do you do your business?
            </h3>
          </CardHeader>
          <CardContent>
            <OrganizationForm />
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default ProfileScreen;

import { mockLocations } from "@/components/types/internal/types/FormDataTypes";
import useStore from "@/zustand/store";
import React, { useState, useEffect } from "react";
import { useFormContext, Controller, FieldValues, FieldError } from "react-hook-form";
import { X } from "lucide-react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

interface LocationFieldProps {
  value?: string[];
}

const LocationField: React.FC<LocationFieldProps> = ({ value }) => {
  const { control, setValue, formState: { errors }, trigger } = useFormContext<FieldValues>();
  const [locations, setLocations] = useState<string[]>(value || []);
  const [currentLocation, setCurrentLocation] = useState<string>("");
  const [duplicateError, setDuplicateError] = useState<string>("");

  const setLocation = useStore((state) => state.setLocation);

  // Update locations when the value prop changes
  useEffect(() => {
    if (value) {
      setLocations(value);
      setLocation(value);
    }
  }, [value]);

  // Handle adding a location
  const handleAddLocation = (location: string) => {
    if (location && locations.length < 10) {
      if (locations.includes(location)) {
        setDuplicateError(`"${location}" is already in your locations.`);
      } else {
        const newLocations = [...locations, location];
        setLocations(newLocations);
        setValue("location", newLocations);
        setLocation(newLocations);
        setCurrentLocation(""); // Reset search input after selection
        trigger("location");
        setDuplicateError(""); // Clear any previous duplicate error
      }
    }
  };

  // Handle removing a location
  const handleRemoveLocation = (index: number) => {
    const newLocations = locations.filter((_, i) => i !== index);
    setLocations(newLocations);
    setValue("location", newLocations);
    setLocation(newLocations);
    trigger("location");
  };

  const filteredLocations = mockLocations.filter((location) =>
    location.toLowerCase().includes(currentLocation.toLowerCase())
  );

  const locationError = errors.location as FieldError | undefined;
  const interestLimitReached = locations.length >= 10;

  return (
    <div className="flex flex-col mt-4 mb-4 font-Inter">
      <Label htmlFor="location" className="font-semibold text-sm lg:text-base mb-2">
        Locations
      </Label>

      {/* Search input for entering locations */}
      <div className="relative">
        <Controller
          name="location"
          control={control}
          rules={{
            validate: (value) => (value && value.length > 0) || "At least one location is required",
          }}
          render={() => (
            <Input
              type="text"
              value={currentLocation}
              onChange={(e) => {
                setCurrentLocation(e.target.value);
                setDuplicateError(""); // Reset duplicate error
              }}
              placeholder={
                interestLimitReached
                  ? "You can't add more locations. Remove an existing one to update."
                  : "Type to search locations"
              }
              className={`border p-2 rounded-md w-full ${interestLimitReached ? "bg-gray-100 text-gray-500 cursor-not-allowed" : ""
                }`}
              disabled={interestLimitReached}
            />
          )}
        />

        {/* Location suggestions */}
        {currentLocation && !interestLimitReached && (
          <ul className="absolute z-10 w-full mt-1 bg-white dark:bg-background border rounded-md shadow-lg max-h-48 overflow-y-auto">
            {filteredLocations.length > 0 ? (
              filteredLocations.map((location) => (
                <li
                  key={location}
                  onClick={() => !locations.includes(location) && handleAddLocation(location)} // Prevent adding already selected locations
                  className={`p-2 cursor-pointer ${locations.includes(location)
                    ? "text-gray-400 cursor-not-allowed" // Disable added locations
                    : "hover:bg-blue-500 hover:text-white"
                    }`}
                >
                  {location}
                  {locations.includes(location) && (
                    <span className="text-xs text-gray-500 ml-2">(Already added)</span>
                  )}
                </li>
              ))
            ) : (
              <li className="p-2 text-gray-500 cursor-default">this location currently not avaialble</li>
            )}
          </ul>
        )}
      </div>

      {/* Display duplicate error message */}
      {duplicateError && <p className="text-red-500 text-sm pb-2 px-2">{duplicateError}</p>}

      {/* Display validation error message */}
      {locationError?.message && (
        <p className="text-red-500 text-sm pb-2 px-2">{locationError.message}</p>
      )}

      {/* Display list of added locations */}
      {locations.length > 0 && (
        <div className="mt-4">
          <h4 className="font-semibold text-sm lg:text-base">Added Locations:</h4>
          <ul className="list-none flex flex-wrap gap-2">
            {locations.map((location, index) => (
              <li key={index} className="flex items-center space-x-2 text-sm">
                <span className="bg-gray-200 text-gray-800 py-1 px-4 rounded-full flex items-center space-x-2">
                  <span>{location}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveLocation(index)}
                    className="text-red-500 hover:text-red-700 p-1"
                  >
                    <X size={14} />
                  </button>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LocationField;

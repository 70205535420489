import { ReactElement, useState } from "react";
import { useToast } from "@/components/ui/use-toast"; // Adjust the import path as needed
import useStore from "@/zustand/store";

export function useMultiStep(steps: ReactElement[]) {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { toast } = useToast(); // Initialize the toast hook

  // Extract role from the Zustand store
  const { role } = useStore((state) => ({
    role: state.role,
  }));

  function next() {
    // Prevent users with the "VIEWER" role from advancing
    if (role === "VIEWER") {
      toast({
        title: "Permission Denied",
        description: "You don't have permission to advance to the next step.",
        variant: "destructive", // Use 'destructive' for error
      });
      return;
    }

    setCurrentStepIndex((i) => {
      if (i >= steps.length - 1) return i;
      return i + 1;
    });
  }

  function back() {
    setCurrentStepIndex((i) => {
      if (i <= 0) return i;
      return i - 1;
    });
  }

  function goTo(index: number) {
    if (index < 0 || index >= steps.length) return;
    setCurrentStepIndex(index);
  }

  return {
    currentStepIndex,
    step: steps[currentStepIndex],
    steps,
    isFirstStep: currentStepIndex === 0,
    isLastStep: currentStepIndex === steps.length - 1,
    goTo,
    next,
    back,
  };
}

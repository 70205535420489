import React from "react";
import { format } from "date-fns";
import { CalendarDays, CalendarIcon } from "lucide-react";
import { DateRange } from "react-day-picker";
import { Controller } from "react-hook-form";
import { Info } from "lucide-react"; // Import Info icon

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Label } from "@/components/ui/label"; // Assuming you're using your custom Label component

interface DatePickerWithRangeProps {
  control: any; // react-hook-form control
  watch: any;   // react-hook-form watch
  errors: any;  // react-hook-form errors
  setValue: any; // react-hook-form setValue
}

export const DatePickerWithRange: React.FC<DatePickerWithRangeProps> = ({
  control,
  errors,
  setValue,
}) => {
  const today = new Date(); // Get today's date
  today.setHours(0, 0, 0, 0); // Set the time to midnight to compare only the date part

  const currentMonth = new Date(today.getFullYear(), today.getMonth(), 1); // First day of the current month
  const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1); // First day of the next month

  return (
    <div className={cn("grid gap-2")}>
      <div className="flex flex-row items-center">
        {/* Label with Info icon and Tooltip */}
        <Label className="block text-sm font-semibold">Date Range</Label>
        <span className="ml-2 hidden lg:block cursor-pointer group">
          <Info size={14} className="group-hover:cursor-default" />
          {/* Tooltip appears only when hovering on Info icon */}
          <div className="absolute left-[644px] -mt-9 items-center hidden group-hover:flex z-50">
            <div className="relative bg-gray-300 text-black border-2 border-gray-300 rounded-lg shadow-md p-4 max-w-xs w-[196px] h-[119px]">
              <span className="text-sm">Set the maximum Date range you want to Select on this campaign.</span>
              <div
                className="absolute top-2 left-0 transform -translate-x-full mt-2 w-0 h-0
            border-t-[10px] border-t-transparent
            border-b-[10px] border-b-transparent
            border-r-[15px] border-r-gray-300"
              ></div>
            </div>
          </div>
        </span>
      </div>

      <Controller
        name="dateRange"
        control={control}
        rules={{
          validate: (value: DateRange | undefined) => {
            if (!value?.from || !value?.to) {
              return "Both start and end dates are required.";
            }
            if (value?.from?.getTime() === value?.to?.getTime()) {
              return "Start date and end date cannot be the same.";
            }
          },
        }}
        render={({ field }) => (
          <>
            <Popover>
              <PopoverTrigger asChild>
                <Button
                  id="date"
                  variant="outline"
                  className={cn(
                    "w-full lg:w-[300px] justify-start text-left font-normal rounded-full p-6",
                    !field.value && "text-muted-foreground"
                  )}
                >
                  <div className="relative">
                    {/* Icon for smaller screens */}
                    <CalendarDays className="mr-2 block lg:hidden" size={15} />

                    {/* Icon for larger screens */}
                    <CalendarDays className="mr-2 hidden lg:block" />
                  </div>
                  {field.value?.from ? (
                    field.value.to ? (
                      <>
                        <span className="text-xs lg:text-sm tracking-tighter lg:tracking-normal">
                          {format(new Date(field.value.from), "LLL dd, y")} -{" "}
                          {format(new Date(field.value.to), "LLL dd, y")}
                        </span>

                      </>
                    ) : (
                      format(new Date(field.value.from), "LLL dd, y")
                    )
                  ) : (
                    <span className="ml-2">Pick a date Range</span>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-auto p-0" align="start">
                <Calendar
                  initialFocus
                  mode="range"
                  selected={field.value}
                  onSelect={(range) => {
                    // Check if the selected range is valid (not same start and end)
                    if (range?.from?.getTime() !== range?.to?.getTime()) {
                      field.onChange(range);
                      setValue("startDate", range?.from || null); // Set startDate
                      setValue("endDate", range?.to || null); // Set endDate
                    }
                  }}
                  numberOfMonths={2}
                  disabled={(date) => date < today} // Disable all previous dates but enable today
                  fromMonth={currentMonth} // Display current month
                  toMonth={nextMonth} // Display next month
                />
              </PopoverContent>
            </Popover>
            {errors.dateRange && (
              <p className="text-red-500 text-sm  px-2">
                {errors.dateRange.message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
};

export default DatePickerWithRange;

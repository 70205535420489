import React from "react";
import { FiEye, FiMusic } from "react-icons/fi";
import { FaTrashAlt } from "react-icons/fa";

interface FilePreviewProps {
  file: File;
  onRemove: () => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, onRemove }) => {
  const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2); // Convert size to MB
  const fileURL = URL.createObjectURL(file);

  const openInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <div className="relative flex flex-col lg:flex-row lg:items-start lg:space-x-4 bg-gray-100 p-2 rounded-md dark:bg-background">
      {file.type.startsWith("image") && (
        <div className="flex-shrink-0">
          <img
            src={fileURL}
            alt="Preview"
            className="object-cover w-[69px] h-[133px] rounded-sm "
          />
        </div>
      )}

      {file.type.startsWith("audio") && (
        <div
          className="flex-shrink-0 flex items-center justify-center bg-Primary"
          style={{
            width: "72px",
            height: "97px",
            borderRadius: "4px",
          }}
        >
          <FiMusic className="text-3xl text-gray-600" />
        </div>
      )}

      {file.type.startsWith("video") && (
        <div className="flex-shrink-0">
          <video controls className="w-56">
            <source src={fileURL} type={file.type} />
            Your browser does not support the video element.
          </video>
        </div>
      )}

      <div className="flex-1 mt-2">
        <p className=" text-sm font-normal lg:text-lg lg:font-medium">{file.name}</p>
        <p className="text-sm text-gray-600">Size: {fileSizeInMB} MB</p>
        {file.type.startsWith("audio") && (
          <div className="mt-2 flex items-center gap-2">
            <audio controls className="w-1/2">
              <source src={fileURL} type={file.type} />
              Your browser does not support the audio element.
            </audio>
            <button
              onClick={onRemove}
              className="text-red-500"
              aria-label="Remove file"
            >
              <FaTrashAlt />
            </button>
          </div>
        )}
        {file.type.startsWith("image") && (
          <div className="mt-2 flex flex-row items-center gap-2 ">
            <button
              onClick={() => openInNewTab(fileURL)}
              className="text-gray-600 hover:text-gray-900"
              aria-label="Open in new tab"
            >
              <FiEye className="text-xl" />
            </button>
            <button
              onClick={onRemove}
              className="text-red-500"
              aria-label="Remove file"
            >
              <FaTrashAlt />
            </button>
          </div>
        )}
        {file.type.startsWith("video") && (
          <div className="mt-2 flex items-center gap-2">
            <button
              onClick={() => openInNewTab(fileURL)}
              className="text-gray-600 hover:text-gray-900"
              aria-label="Open in new tab"
            >
              <FiEye className="text-xl" />
            </button>
            <button
              onClick={onRemove}
              className="text-red-500"
              aria-label="Remove file"
            >
              <FaTrashAlt />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilePreview;

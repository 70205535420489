// AppRouter.tsx
import { lazy, ReactNode } from "react";
import { Navigate, Outlet, useRoutes, RouteObject } from "react-router-dom";
import HomeLayout from "@/components/layouts/HomeLayout";
import DashboardLayout from "@/components/layouts/DashboardLayout";
import SuspenseWrapper from "./SuspenseWrapper";
import { ProtectedRoute } from "./ProtectedRoute"; // Import your ProtectedRoute component
// Lazy loaded components
const SimpleLeads = lazy(() => import("@/pages/home/simple-leads"));
const Help = lazy(() => import("@/pages/help"));
const IndividualLogin = lazy(() => import("@/pages/auth/login"));
const OtpLogin = lazy(() => import("@/components/internal/auth/individual/otp"));
const OtpRegister = lazy(() => import("@/components/internal/auth/register/register/otp"));
const OrganizationLogin = lazy(() => import("@/pages/auth/register"));
const OrgLogin = lazy(() => import("@/pages/auth/orglogin/index"));
const OrganizationFinalPage = lazy(() => import("@/components/internal/auth/register/profileComplete/ProfileSuccess"));
import { OrganizationForm } from "@/components/internal/auth/register";

// const AdsVisitPage = lazy(() => import("@/pages/ads/advertisement"));
import AdsVisitPage from "@/pages/ads/advertisement";

const Dashboard = lazy(() => import("@/pages/dashboard"));
const Accountaccess = lazy(() => import("@/pages/account-acess/account"));
const AccountSetting = lazy(() => import("@/pages/accountSettings/accountSetting"));
const GernalAccountSetting = lazy(() => import("@/pages/accountSettings/generalSetting/general"));
const Checkout = lazy(() => import("@/pages/checkOut/cheakout"));
const Returns = lazy(() => import("@/pages/retrun/return"));
const TermCondition = lazy(() => import("@/components/internal/docs/Condition"));
const Wallet = lazy(() => import("@/components/internal/wallet/wallet/wallet"));
const PrivacyPolicy = lazy(() => import("@/components/internal/docs/privarcyPolicy"));
const HowCanWeHelp = lazy(() => import("@/components/internal/docs/help"));
const JammeAdsPolicies = lazy(() => import("@/components/internal/docs/adPolicy"));
const JammeAdsFormat = lazy(() => import("@/components/internal/docs/adFormat"));
const Targeting = lazy(() => import("@/components/internal/docs/Targeting"));
const Suspend = lazy(() => import("@/components/internal/docs/sussped"));
const Overview = lazy(() => import("@/components/internal/docs/targetingOverview"));
const UserGuidelines = lazy(() => import("@/components/internal/docs/UserGuidelines"));
const AboutUs = lazy(() => import("@/components/internal/docs/about-us"));
const Carrier = lazy(() => import("@/components/internal/docs/carrier"));
const ORGOtpLogin = lazy(
  () => import("@/components/internal/auth/orgLogin/otp")
);
import { NotFound } from "@/pages/common";

// TypeScript interface for route configuration
interface RouteConfig {
  path: string;
  layout: React.ComponentType<{ children: ReactNode }>;
  routes: Omit<RouteObject, "children">[];
}

// Route configuration
const routesConfig: RouteConfig[] = [
  {
    path: "/",
    layout: HomeLayout,
    routes: [
      { index: true, element: <SimpleLeads /> },
      { path: "help", element: <Help /> },
      { path: "login", element: <ProtectedRoute element={<IndividualLogin />} isPublic /> }, // Public route
      { path: "login/otp", element: <ProtectedRoute element={<OtpLogin />} isPublic /> }, // Public route
      { path: "register/otp", element: <ProtectedRoute element={<OtpRegister />} isPublic /> }, // Public route
      { path: "register", element: <ProtectedRoute element={<OrganizationLogin />} isPublic /> }, // Public route
      { path: "register/profile", element: <ProtectedRoute element={<OrganizationForm />} isPublic /> }, // Public route
      { path: "register/complete", element: <ProtectedRoute element={<OrganizationFinalPage />} isPublic /> }, // Public route
      { path: "orglogin", element: <ProtectedRoute element={<OrgLogin />} isPublic /> }, // Public route
      { path: "orglogin/otp", element: <ProtectedRoute element={<ORGOtpLogin />} isPublic /> }, // Public route
      { path: "ads", element: <ProtectedRoute element={<AdsVisitPage />} /> },
      { path: "checkout", element: <ProtectedRoute element={<Checkout />} /> },
      { path: "wallet", element: <ProtectedRoute element={<Wallet />} /> },
      { path: "accountaccess", element: <ProtectedRoute element={<Accountaccess />} /> },
    ],
  },
  {
    path: "/dashboard",
    layout: DashboardLayout,
    routes: [
      { path: "", element: <ProtectedRoute element={<Dashboard />} /> },
      { path: "setting", element: <ProtectedRoute element={<AccountSetting />} /> },
      { path: "setting/general", element: <ProtectedRoute element={<GernalAccountSetting />} /> },
      { path: "setting/taxinfo", element: <ProtectedRoute element={<AccountSetting />} /> },
    ],
  },
  {
    path: "/docs",
    layout: HomeLayout,
    routes: [
      { path: "termCondition", element: <TermCondition /> },
      { path: "suspend", element: <Suspend /> },
      { path: "privacy", element: <PrivacyPolicy /> },
      { path: "policies", element: <JammeAdsPolicies /> },
      { path: "adsformat", element: <JammeAdsFormat /> },
      { path: "targeting", element: <Targeting /> },
      { path: "overview", element: <Overview /> },
      { path: "guidelines", element: <UserGuidelines /> },
      { path: "about-us", element: <AboutUs /> },
      { path: "carrier", element: <Carrier /> },
    ],
  },
  { path: "return", layout: HomeLayout, routes: [{ path: "", element: <Returns /> }] },
  { path: "helps", layout: HomeLayout, routes: [{ path: "", element: <HowCanWeHelp /> }] },
];

// Common routes (404, fallback)
const commonRoutes: RouteObject[] = [
  {
    path: "/404",
    element: (
      <SuspenseWrapper>
        <NotFound />
      </SuspenseWrapper>
    ),
  },
  { path: "*", element: <Navigate to="/404" replace /> },
];

// Helper function to generate routes dynamically
function generateRoutes(config: RouteConfig[]): RouteObject[] {
  return config.map(({ path, layout: Layout, routes }) => ({
    path,
    element: (
      <Layout>
        <SuspenseWrapper>
          <Outlet />
        </SuspenseWrapper>
      </Layout>
    ),
    children: routes,
  }));
}

// Main Router component
export default function AppRouter() {
  const routes = useRoutes([...generateRoutes(routesConfig), ...commonRoutes]);
  return routes;
}

import React from "react";
import { motion } from "framer-motion";

type StepIndicatorProps = {
  currentStepIndex: number;
  totalSteps: number;
};

type StepProps = {
  index: number;
  label: string;
  isActive: boolean;
  isCompleted: boolean;
};

const Step: React.FC<StepProps> = ({ index, label, isActive, isCompleted }) => (
  <motion.div
    className={`flex items-center px-1 gap-1 lg:gap-2 ${
      isActive
        ? "bg-orange-300 rounded-full py-2 md:px-4 md:py-2 lg:px-4 lg:py-2"
        : ""
    }`}
    initial={{ opacity: 0, scale: 0.95 }}
    animate={{ opacity: 1, scale: 1 }}
    exit={{ opacity: 0, scale: 0.95 }}
    transition={{ duration: 0.3, ease: "easeInOut" }}
    layout
  >
    <motion.div
      className={`flex items-center justify-center w-7 h-7 lg:w-8 lg:h-8 rounded-full ${
        isCompleted ? "bg-green-500" : isActive ? "bg-orange-300" : "bg-white"
      } ${isCompleted ? "border-none" : "border border-black"}`}
      initial={{ scale: 0.95 }}
      animate={{ scale: 1 }}
      exit={{ scale: 0.95 }}
      transition={{ duration: 0.3, ease: "easeInOut" }}
      layout
    >
      {isCompleted ? (
        <svg
          className="w-4 h-4 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 12l5 5L20 7"
          />
        </svg>
      ) : (
        <span
          className={`text-${isActive ? "black" : "gray-800"} lg:font-medium text-xs lg:text-sm`}
        >
          {index + 1}
        </span>
      )}
    </motion.div>
    <span
      className={`text-gray-800 font-Inter lg:p-1 lg:font-medium  text-[.6rem] lg:text-sm ${
        isActive ? "text-orange-800 font-bold" : ""
      }`}
    >
      {label}
    </span>
  </motion.div>
);

const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStepIndex }) => {
  const steps = ["Create Ad", "Add audio", "Customize delivery"];

  return (
    <div className="flex w-full pr-3 items-center justify-center bg-gray-50 p-1 lg:p-2 fixed left-[10px] md:static lg:static overflow-hidden dark:bg-background z-10">
      <motion.div
        className="flex items-center justify-center rounded-full border-2 border-gray-400 lg:gap-4"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        layout
      >
        {steps.map((label, index) => (
          <Step
            key={index}
            index={index}
            label={label}
            isActive={index === currentStepIndex}
            isCompleted={index < currentStepIndex}
          />
        ))}
      </motion.div>
    </div>
  );
};

export default StepIndicator;
